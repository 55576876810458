import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import Home from "./pages/Home/Home";
import ScienceFields from "./pages/ScienceFields/ScienceFields";
import { ElementGames } from "./pages/ElementGames/ElementGames";
import SpaceGame from "./pages/ElementGames/SpaceGame/SpaceGame";
import FireGame from "./pages/ElementGames/FireGame/FireGame";
import AirGame from "./pages/ElementGames/AirGame/AirGame";
import WaterGame from "./pages/ElementGames/WaterGame/WaterGame";
import EarthGame from "./pages/ElementGames/EarthGame/EarthGame";
import EquationGallery from "./pages/EquationGallery/EquationGallery";
import Desmos from "./pages/EquationGallery/Desmos/Desmos";
import Harmonograph from "./pages/EquationGallery/Harmonograph/Harmonograph";
import Attractors from "./pages/EquationGallery/Attractors/Attractors";
import Facts from "./pages/EquationGallery/Facts/Facts";
import ScienceApplication from "./pages/ScienceApplications/ScienceApplication";
import IndianMinds from "./pages/IndianMinds/IndianMinds";
import CVRaman from "./pages/IndianMinds/CVRaman/CVRaman";
import PitchDeck from "./pages/PitchDeck/PitchDeck";
import Pysarun from "./pages/ScienceApplications/Pysarun/Pysarun";
import Poppus from "./pages/ScienceApplications/EyeOfPuppusChain/Puppus";
import Rorschachgenerator from "./pages/ScienceApplications/Rorschachgenerator/Rorschachgenerator";
import ElectricSphere from "./pages/ScienceApplications/ElectricSphere/ELectectricSphere";
import Recurssivecircle from "./pages/ScienceApplications/RecurssiveCircle/RecurssiveCircle";

import Circle from "./pages/ScienceApplications/Circle/Circle";
import Penrose from "./pages/ScienceApplications/PenroseZoom/PenroseZoom";
import QardTree from "./pages/ScienceApplications/QardTree/QardTree";
import DoubleDip from "./pages/ScienceApplications/DoubleDIp/DoubleDip";
import Factral from "./pages/ScienceApplications/Factral/Factral";
import Mandala from "./pages/ScienceApplications/Mandala/Mandala";
import Cardoid from "./pages/ScienceApplications/Cardoiod/Cardoid";
import TrignometryPlane from "./pages/ScienceApplications/TrignometryPlane/TrignometryPlane";
import PerlinNoiseASCII from "./pages/ScienceApplications/ASCII_Island/ASCIIisland";
import WavesOnSphere from "./pages/ScienceApplications/WavesOnSphere/WavesOnSphere";
import DigitalOrganism from "./pages/ScienceApplications/DigitalOrganism/DigitalOrganism";
import CrystalSucculent from "./pages/ScienceApplications/CrystalSucculent/CrystalSucculent";
import DreamCatcher from "./pages/ScienceApplications/DreamCatcher/DreamCatcher";
import SierpinskiWireframe from "./pages/ScienceApplications/SierpinskiWireframe/SierpinskiWireframe";
import CursedTriangle from "./pages/ScienceApplications/CursedTriangle/CursedTriangle";
import SquarePattern from "./pages/ScienceApplications/SquarePattern/SquarePattern";
import MandalaOfElements from "./pages/ScienceApplications/MandalaOfElements/MandalaOfElements";
import SqaureFlower from "./pages/ScienceApplications/SquareFlower/SquareFlower";
import Trignometry from "./pages/ScienceApplications/Trignometry/Trignometry";
import TrignometryFlower from "./pages/ScienceApplications/TrignometryFlower/TrignometryFlower";
import Scope from "./pages/ScienceApplications/Scope/Scope";
import TrippyTriangle from "./pages/ScienceApplications/TrippyTriangle/TrippyTriangle";
import NodeSpring from "./pages/ScienceApplications/NodeSpring/NodeSrping";
import ExplodingMouse from "./pages/ScienceApplications/ExplodingMouse/ExplodingMouse";
import FlowerDonut from "./pages/ScienceApplications/FlowerDonut/FlowerDonut";
import FactralLand from "./pages/ScienceApplications/FactralLand/FactralLand";
import GlassBreaker from "./pages/ScienceApplications/GlassBreaker/GlassBreaker";
import SlimeMoldSimulation from "./pages/ScienceApplications/SlimeMoldSimulation/SlimeMoldSimulation";
import RandomLissajousWebs from "./pages/ScienceApplications/RandomLissajousWebs/RandomLissajousWebs";
import QuadTreeBlob from "./pages/ScienceApplications/QuadTreeBlob/QuadTreeBlob";
import Diverge from "./pages/ScienceApplications/Diverge/Diverge";

import FloatingFunnel from "./pages/ScienceApplications/FloatingFunnel/FloatingFunnel";
import TunnelVision from "./pages/ScienceApplications/TunnelVision/TunnelVision";
import StrangeVibration from "./pages/ScienceApplications/StrangeVibration/StrangeVibration";
import OilSplill from "./pages/ScienceApplications/230609a/OilSplill";
import OilWaterPhaseSeparation from "./pages/ScienceApplications/Gassball/OilWaterPhaseSeparation";
import Jamsetji from "./pages/IndianMinds/JamshetjiTata/Jamsetji";
import Evolution from "./pages/EvolutionOfScience/Evolution";
import APJAbdulKalam from "./pages/IndianMinds/APJAbdulKalam/APJAbdulKalam";
import FunScience from "./pages/FunScience/FunScience";
import ScienceFieldsKiosk from "./pages/ScienceFieldsKiosk/ScienceFieldsKiosk";
import IncityGallery from "./pages/Parsec/IncityGallery";
import TactileGallery from "./pages/Parsec/TactileGallery/TactileGallery";
import IllusionGallery from "./pages/Parsec/IllusionGallery/IllusionGallery";
import KineticKingdomGallery from "./pages/Parsec/KineticKingdomGallery/KineticKingdomGallery";
import CardboardGallery from "./pages/Parsec/CardboardGallery/CardboardGallery";
import DigitalGallery from "./pages/Parsec/DigitalGallery/DigitalGallery";
import IntroGallery from "./pages/Parsec/IntroGallery/IntroGallery";
import Structure from "./components/parsec/Exhibits/Structure";
import PressureSensetiveWall from "./components/parsec/TactileExhibits/PressureSensetiveWall";
import InteractiveIlluminations from "./components/parsec/TactileExhibits/InteractiveIlluminations";
import SurfaceSpectrum from "./components/parsec/TactileExhibits/SurfaceSpectrum";
import EcoSimulator from "./components/parsec/TactileExhibits/EcoSimulator";
import TextureTiles from "./components/parsec/TactileExhibits/TextureTiles";
import VandeGraafGenerator from "./components/parsec/TactileExhibits/VandeGraafGenerator";
import BedOfNails from "./components/parsec/TactileExhibits/BedOfNails";
import ThermalDeceit from "./components/parsec/TactileExhibits/ThermalDeceit";
import BridgeOfResponse from "./components/parsec/TactileExhibits/BridgeOfResponse";
import ThigmoSprouts from "./components/parsec/TactileExhibits/ThigmoSprouts";
import PinCushionWall from "./components/parsec/TactileExhibits/PinCushionWall";
import AsruniAmbigram from "./components/parsec/IllusionExhibits/AsruniAmbigram";
import EyesOfKrodha from "./components/parsec/IllusionExhibits/EyesOfKrodha";
import AnarmophicAnuraga from "./components/parsec/IllusionExhibits/AnarmophicAnuraga";
import FunDarpan from "./components/parsec/IllusionExhibits/FunDarpan";
import MayaMorphs from "./components/parsec/IllusionExhibits/MayaMorphs";
import AnantaAbyss from "./components/parsec/IllusionExhibits/AnantaAbyss";
import StillnessOfShanti from "./components/parsec/IllusionExhibits/StillnessOfShanti";
import SurmountSimhasana from "./components/parsec/IllusionExhibits/SurmountSimhasana";
import JugupsaGenesis from "./components/parsec/IllusionExhibits/JugupsaGenesis";
import NavarasaARWall from "./components/parsec/IllusionExhibits/NavarasaARWall";
import Highlights from "./components/parsec/Highlights/Highlights";
import WaterDropAutomata from "./components/parsec/KineticExhibits/WaterDropAutomata";
import WalkingBicycle from "./components/parsec/KineticExhibits/WalkingBicycle";
import SlitheringSymphony from "./components/parsec/KineticExhibits/SlitheringSymphony";
import KineticSquareWave from "./components/parsec/KineticExhibits/KineticSquareWave";
import HarmonographEx from "./components/parsec/KineticExhibits/HarmonographEx";
import DigitFlipper from "./components/parsec/KineticExhibits/DigitFlipper";
import MechanicalMultiplier from "./components/parsec/KineticExhibits/MechanicalMultiplier";
import PulleySystems from "./components/parsec/KineticExhibits/PulleySystems";
import KineticIllusion from "./components/parsec/KineticExhibits/KineticIllusion";
import TimeGlider from "./components/parsec/KineticExhibits/TimeGlider";
import HobermanSphere from "./components/parsec/KineticExhibits/HobermanSphere";
import VisitUs from "./pages/VisitUs/VisitUs";
import FAQ from "./pages/Parsec/FAQ/FAQ";
import GuideLines from "./pages/GuideLines/GuideLines";
import DonationBox from "./components/parsec/IntroExhibits/DonationBox";
import WisdomTree from "./components/parsec/IntroExhibits/WisdomTree";
import HologramFan from "./components/parsec/IntroExhibits/HologramFan";
import LuminousLayers from "./components/parsec/IntroExhibits/LuminousLayers";
import ActionMuralShowcase from "./components/parsec/TactileExhibits/ActionMuralShowcase";
import EnergyMocap from "./components/parsec/DigitalExhibits/EnergyMocap";
import TimeMocap from "./components/parsec/DigitalExhibits/TimeMocap";
import Rangolify from "./components/parsec/DigitalExhibits/Rangolify";
import Rangolify2 from "./components/parsec/DigitalExhibits/Rangolify2.0";
import DigitalDoodleDual from "./components/parsec/DigitalExhibits/DigitalDoodleDual";
import HandsOnOpera from "./components/parsec/DigitalExhibits/HandsOnOpera";
import AvatarGenerator from "./pages/ScienceAvatar/ScienceAvatar";
import QuestionsContainer from "./pages/Contraversial_poll/Contraversial";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  const location = useLocation();
  function handlePopState() {
    window.location.reload();
  } // Add event listener for the popstate event
  window.addEventListener("popstate", handlePopState);

  return (
    <ChakraProvider>
      <React.Fragment key={location.pathname}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/indian-minds" element={<IndianMinds />} />
          <Route path="/cv-raman" element={<CVRaman />} />
          <Route path="/jamshetji-tata" element={<Jamsetji />} />
          <Route path="/apj-abdul-kalam" element={<APJAbdulKalam />} />
          <Route path="/fields-of-science" element={<ScienceFields />} />
          <Route path="/gaming-zone" element={<ElementGames />} />
          <Route path="/space-game" element={<SpaceGame />} />
          <Route path="/fire-game" element={<FireGame />} />
          <Route path="/air-game" element={<AirGame />} />
          <Route path="/water-game" element={<WaterGame />} />
          <Route path="/earth-game" element={<EarthGame />} />
          <Route path="/equational-gallery" element={<EquationGallery />} />
          <Route path="/desmos" element={<Desmos />} />
          <Route path="/harmonograph" element={<Harmonograph />} />
          <Route path="/Attractors" element={<Attractors />} />
          <Route path="/facts" element={<Facts />} />

          {/*   Sciene Application   */}
          <Route path="/Mathemartica" element={<ScienceApplication />} />
          {/*   Sciene Applications   */}
          <Route path="/BallAnimation" element={<Pysarun />} />
          <Route path="/EyeOfPoppusChain" element={<Poppus />} />
          <Route path="/Rorschachgenerator" element={<Rorschachgenerator />} />
          <Route path="/ElectricSphere" element={<ElectricSphere />} />
          <Route path="/StrangeVibration" element={<StrangeVibration />} />
          <Route path="/Recurssivecircle" element={<Recurssivecircle />} />
          <Route
            path="/OilWaterPhaseSeparation"
            element={<OilWaterPhaseSeparation />}
          />
          <Route path="/Circle" element={<Circle />} />
          <Route path="/Penrose" element={<Penrose />} />
          <Route path="/QardTree" element={<QardTree />} />
          <Route path="/DoubleDip" element={<DoubleDip />} />
          <Route path="/Fractal" element={<Factral />} />
          <Route path="/Mandala" element={<Mandala />} />
          <Route path="/Cardoiod" element={<Cardoid />} />
          <Route path="/TrignometryPlane" element={<TrignometryPlane />} />
          <Route path="/PerlinNoiseASCII" element={<PerlinNoiseASCII />} />
          <Route path="/WavesOnSphere" element={<WavesOnSphere />} />
          <Route path="/DigitalOrganism" element={<DigitalOrganism />} />
          <Route path="/CrystalSucculent" element={<CrystalSucculent />} />
          <Route path="/DreamCatcher" element={<DreamCatcher />} />
          <Route
            path="/SierpinskiWireframe"
            element={<SierpinskiWireframe />}
          />
          <Route path="/CursedTriangle" element={<CursedTriangle />} />
          <Route path="/SquarePattern" element={<SquarePattern />} />
          <Route path="/MandalaOfElements" element={<MandalaOfElements />} />
          <Route path="/SquareFlower" element={<SqaureFlower />} />
          <Route path="/Trignometry" element={<Trignometry />} />
          <Route path="/TrignometryFlower" element={<TrignometryFlower />} />
          <Route path="/Scope" element={<Scope />} />
          <Route path="/TrippyTriangle" element={<TrippyTriangle />} />
          <Route path="/NodeSpring" element={<NodeSpring />} />
          <Route path="/ExplodingMouse" element={<ExplodingMouse />} />
          <Route path="/FlowerDonut" element={<FlowerDonut />} />
          <Route path="/FractallLand" element={<FactralLand />} />
          <Route path="/GlassBreaker" element={<GlassBreaker />} />
          <Route
            path="/SlimeMoldSimulation"
            element={<SlimeMoldSimulation />}
          />
          <Route
            path="/RandomLissajousWebs"
            element={<RandomLissajousWebs />}
          />
          <Route path="/OilSplill" element={<OilSplill />} />
          <Route path="/QuadTreeBlob" element={<QuadTreeBlob />} />
          <Route path="/Diverge" element={<Diverge />} />
          <Route path="/FloatingFunnel" element={<FloatingFunnel />} />
          <Route path="/TunnelVision" element={<TunnelVision />} />

          {/*   Evolution Page   */}
          <Route
            path="/timeline-of-reality"
            element={<Evolution kiosk={false} />}
          />

          <Route path="/fun-with-science" element={<FunScience />} />

          {/* Incity Gallery Pages */}
          <Route path="/parsec" element={<IncityGallery />} />
          <Route path="/parsec/visit-us" element={<VisitUs />} />
          <Route path="/parsec/faqs" element={<FAQ />} />
          <Route path="/parsec/guidelines" element={<GuideLines />} />
          <Route path="/parsec/tactile-gallery" element={<TactileGallery />} />
          <Route
            path="/parsec/illusion-gallery"
            element={<IllusionGallery />}
          />
          <Route
            path="/parsec/kinetic-gallery"
            element={<KineticKingdomGallery />}
          />
          <Route
            path="/parsec/cardborad-gallery"
            element={<CardboardGallery />}
          />
          <Route path="/parsec/digital-gallery" element={<DigitalGallery />} />
          <Route path="/parsec/intro-gallery" element={<IntroGallery />} />

          {/* Incity Gallery Exhibit Pages */}
          {/* Sample Exhibit Page */}
          <Route path="/parsec/structure" element={<Structure />} />

          {/*------------------------------------------- Tactile Gallery Exhibits -------------------------------------------*/}
          <Route
            path="/parsec/tactile-gallery/pressure-sensitive-wall"
            element={<PressureSensetiveWall />}
          />
          <Route
            path="/parsec/tactile-gallery/interactive-Illuminations"
            element={<InteractiveIlluminations />}
          />
          <Route
            path="/parsec/tactile-gallery/surface-spectrum"
            element={<SurfaceSpectrum />}
          />
          <Route
            path="/parsec/tactile-gallery/the-eco-simulator"
            element={<EcoSimulator />}
          />
          <Route
            path="/parsec/tactile-gallery/texture-tiles"
            element={<TextureTiles />}
          />
          <Route
            path="/parsec/tactile-gallery/van-de-graaff-generator"
            element={<VandeGraafGenerator />}
          />
          <Route
            path="/parsec/tactile-gallery/bed-of-nails"
            element={<BedOfNails />}
          />
          <Route
            path="/parsec/tactile-gallery/thermal-deceit"
            element={<ThermalDeceit />}
          />
          <Route
            path="/parsec/tactile-gallery/bridge-of-response"
            element={<BridgeOfResponse />}
          />
          <Route
            path="/parsec/tactile-gallery/thigmo-sprouts"
            element={<ThigmoSprouts />}
          />
          <Route
            path="/parsec/tactile-gallery/pin-cushion-wall"
            element={<PinCushionWall />}
          />
          <Route
            path="/parsec/tactile-gallery/action-mural-showcase"
            element={<ActionMuralShowcase />}
          />

          {/*------------------------------------------- Illusion Gallery Exhibits -------------------------------------------*/}
          <Route
            path="/parsec/illusion-gallery/asruni-ambigram"
            element={<AsruniAmbigram />}
          />
          <Route
            path="/parsec/illusion-gallery/1000-eyes-of-krodha"
            element={<EyesOfKrodha />}
          />
          <Route
            path="/parsec/illusion-gallery/anamorphic-anuraga"
            element={<AnarmophicAnuraga />}
          />
          <Route
            path="/parsec/illusion-gallery/fun-darpan"
            element={<FunDarpan />}
          />
          <Route
            path="/parsec/illusion-gallery/maya-morphs"
            element={<MayaMorphs />}
          />
          <Route
            path="/parsec/illusion-gallery/ananta-abyss"
            element={<AnantaAbyss />}
          />
          <Route
            path="/parsec/illusion-gallery/stillness-of-shanti"
            element={<StillnessOfShanti />}
          />
          <Route
            path="/parsec/illusion-gallery/surmount-simhasana"
            element={<SurmountSimhasana />}
          />
          <Route
            path="/parsec/illusion-gallery/jugupsa-genesis"
            element={<JugupsaGenesis />}
          />
          <Route
            path="/parsec/illusion-gallery/navarasa-ar-wall"
            element={<NavarasaARWall />}
          />

          {/*------------------------------------------- Kinetic Gallery Exhibits -------------------------------------------*/}
          <Route
            path="/parsec/kinetic-gallery/water-drop-automata"
            element={<WaterDropAutomata />}
          />
          <Route
            path="/parsec/kinetic-gallery/walking-bicycle"
            element={<WalkingBicycle />}
          />
          <Route
            path="/parsec/kinetic-gallery/slithering-symphony"
            element={<SlitheringSymphony />}
          />
          <Route
            path="/parsec/kinetic-gallery/kinetic-square-wave"
            element={<KineticSquareWave />}
          />
          <Route
            path="/parsec/kinetic-gallery/harmonograph"
            element={<HarmonographEx />}
          />
          <Route
            path="/parsec/kinetic-gallery/digit-flipper"
            element={<DigitFlipper />}
          />
          <Route
            path="/parsec/kinetic-gallery/mechanical-multiplier"
            element={<MechanicalMultiplier />}
          />
          <Route
            path="/parsec/kinetic-gallery/pulley-systems"
            element={<PulleySystems />}
          />
          <Route
            path="/parsec/kinetic-gallery/kinetic-illusion"
            element={<KineticIllusion />}
          />
          <Route
            path="/parsec/kinetic-gallery/time-glider"
            element={<TimeGlider />}
          />
          <Route
            path="/parsec/kinetic-gallery/hoberman-sphere"
            element={<HobermanSphere />}
          />

          {/*------------------------------------------- INTRO GALLERY -------------------------------------------*/}

          <Route
            path="/parsec/intro-gallery/donation-box"
            element={<DonationBox />}
          />
          <Route
            path="/parsec/intro-gallery/wisdom-tree"
            element={<WisdomTree />}
          />
          <Route
            path="/parsec/intro-gallery/hologram-fan"
            element={<HologramFan />}
          />
          <Route
            path="/parsec/intro-gallery/luminous-layers"
            element={<LuminousLayers />}
          />

          {/*------------------------------------------- DIGITAL GALLERY -------------------------------------------*/}

          <Route
            path="/parsec/digital-gallery/energy-mocap"
            element={<EnergyMocap />}
          />
          <Route
            path="/parsec/digital-gallery/time-mocap"
            element={<TimeMocap />}
          />
          <Route
            path="/parsec/digital-gallery/rangolify"
            element={<Rangolify />}
          />
          <Route
            path="/parsec/digital-gallery/rangolify2.o"
            element={<Rangolify2 />}
          />
          <Route
            path="/parsec/digital-gallery/digital-doodle-dual"
            element={<DigitalDoodleDual />}
          />
          <Route
            path="/parsec/digital-gallery/hands-on-opera"
            element={<HandsOnOpera />}
          />

          <Route path="/parsec/highlights/:id" element={<Highlights />} />

          {/* Hidden Paths */}
          <Route path="/pitch-deck" element={<PitchDeck />} />
          <Route
            path="/fields-of-science-kiosk"
            element={<ScienceFieldsKiosk />}
          />
          {/*   Evolution Page for kiosk   */}
          <Route
            path="/timeline-of-reality-kiosk"
            element={<Evolution kiosk={true} />}
          />
          {/* ---------------------------science avatar generator ----------------------------------------- */}

          <Route path="/avatar" element={<AvatarGenerator />} />


          {/*..........................Contraversial poll............................*/}

          <Route path="/CuriousConundrums" element={<QuestionsContainer/>}/>
        </Routes>
      </React.Fragment>
    </ChakraProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
