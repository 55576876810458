import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "./CarouselComponent.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export default function CarouselComponent({ cardData }) {
  const screenWidth = window.innerWidth;
  const sliderRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: screenWidth > 450 ? "30%" : "12%",
    draggable: true,
    // Add additional settings here
  };

  useEffect(() => {
    // Function to handle click
    const handleDotClick = (index) => {
      console.log("Dot clicked, going to slide:", index);
      sliderRef.current.slickGoTo(index);
    };

    // Get all the dots
    const dots = document.querySelectorAll(".slick-dots li button");

    // Attach the click handler to each dot
    dots.forEach((dot, index) => {
      dot.onclick = () => handleDotClick(index);
    });

    // Clean up function to remove click handlers
    return () => {
      dots.forEach((dot) => {
        dot.onclick = null;
      });
    };
  }, []);

  return (
    <div className="carouselSlider">
      <div className="left"></div>
      <div className="right"></div>
      <Slider ref={sliderRef} {...settings}>
        {cardData.map((card, index) => (
          <Link key={index} to={card.linkURL}>
            <div className="carousel-slide">
              <div className="darkOverlay"></div>
              <img src={card.bannerUrl} alt={`Slide ${index}`} />
              <div className="slideDescription">
                <h2>{card.buttonText}</h2>
                <p>{card.galleryDescription}</p>
              </div>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
}
