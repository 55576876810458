const ExhibitData = {
  sample: {
    //Demo Content
    title: "COSMIC CALCULATOR",
    tag: "As you enter the gallery, melodious sounds.",
    bannerImage: "",
    bannerImage2: "",
    keywords: ["Param", "Vijay", "Keywords", "Zebra", "Elephant"],
    scienceRelevancy:
      "As you enter the gallery, melodious sounds will be played when you walk on the steps leading towards the entrance. as you enter the gallery, melodious sounds will be played when you walk on the steps leading towards the entrance. <br /><br />as you enter the gallery, melodious sounds will be played when you walk on the steps leading towards the entrance. As you enter the gallery, melodious sounds will be played when you walk on the steps leading towards the entrance. as you enter the gallery, melodious sounds will be played when you walk on the steps leading towards the entrance.as you enter the gallery, melodious sounds will be played when you walk on the steps leading towards the entrance.",
    keyTakeAway:
      "As you enter the gallery, melodious sounds will be played when you walk on the steps leading towards the entrance.<br /><br />As you enter the gallery, melodious sounds will be played when you walk on the steps leading towards the entrance.",
    //   ytLink: "https://www.youtube.com/watch?v=SWNTrMJkwLY",
  },
  // --------------------------INTRO GALLEY STARTS--------------------------
  // Hologram Fan ------------------------------------------------------
  "Hologram Fan": {
    title: "HOLOGRAM FAN",
    tag: "Visual Magic Through Motion and Light",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IntroGallery/halogram+fan.jpg",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IntroGallery/halogram+fan.jpg",
    keywords: [
      "Holography",
      "LED Technology",
      "Optical Illusion",
      "Persistence of Vision",
      "Motion Blur",
      "Synchronised Technology",
    ],
    scienceRelevancy:
      "This exhibit features a series of LED lights mounted on the blades of multiple fans. Each LED can be individually controlled to turn on, off, change colour, or adjust the intensity according to commands from a central unit. As the fan blades spin, these LEDs light up in precise sequences, creating vivid images that appear to float in mid-air.<br /><br />It’s a blend of mechanical rotation and controlled illumination, showcasing how simple components can produce stunning visual effects.",
    keyTakeAway:
      "<ul><li><p>Persistence of Vision: The human retina retains images for approximately 1/16th of a second. When another image appears within this timeframe, our brains blend the visuals, creating the illusion of continuous motion.</p></li><li><p>By rapidly switching the LEDs’ states while the blades are spinning, distinct images are projected into the air. This precise synchronisation is key to the clarity and fluidity of the holographic images.</p></li><li><p>At high speeds, the fan blades become nearly invisible to the naked eye, reinforcing the illusion that the images are suspended in mid-air without any mechanical support.</p></li><li><p>This effect tricks the brain into perceiving depth and three-dimensional shapes, generating a captivating holographic display.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Donation Box
  "Donation Box": {
    title: "DONATION BOX",
    tag: "Optical Illusion Through Mirrors",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IntroGallery/Donation+box+01.jpg",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IntroGallery/Donation+box+02.jpg",
    keywords: [
      "Reflection",
      "Angle of reflection",
      "Optics",
      "Mirrors",
      "Illusions",
      "Visual Perception",
      "Transparency",
    ],
    scienceRelevancy: `This exhibit presents a unique donation box where visitors drop coins and notes into a slot at the top. Although you might expect to see your donation tumble down behind the transparent front, a clever design twist prevents this view. Instead, what appears to be a straightforward drop is masked by the innovative use of mirrors and transparency.<br /><br />The box features a diagonal mirror that effectively splits it into two compartments. The visible section through the transparent front is just an illusion; your donations actually accumulate unseen in the rear compartment, hidden by the mirror's strategic placement.`,
    keyTakeAway:
      "<ul><li><p>The exhibit leverages the principles of optics and the properties of mirrors to craft an engaging visual illusion.</p></li><li><p>A strategically placed mirror divides the box into two compartments. The visible section through the transparent front is merely a reflection, not the actual compartment where donations fall.</p></li><li><p>This clever setup tricks the observer into thinking they are seeing the entire contents of the box. In reality, donations are collected in the hidden rear compartment.</p></li><li><p>The exhibit showcases how light interacts with reflective surfaces to alter spatial perception and depth, providing a practical demonstration of the angle of reflection and its effects.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Wisdom Tree (Ignore)
  "Wisdom Tree": {
    title: "WISDOM TREE",
    tag: "Explore the Intricacies of Circuit Connections and Problem Solving",
    bannerImage: "",
    bannerImage2: "",
    keywords: [
      "Circuits",
      "Wiring",
      "Switches",
      "Problem-Solving",
      "Strategic Thinking",
      "Electrical Engineering",
    ],
    scienceRelevancy:
      "This engaging puzzle exhibit challenges visitors to illuminate a series of bulbs arranged in a tree-like structure using multiple switches. However, the switches do not have a simple one-to-one correspondence with the bulbs.<br /><br />Participants are tasked with discovering the correct combination of switch toggles to light up all the bulbs, a task that symbolises the achievement of wisdom through problem-solving.",
    keyTakeAway:
      "<ul><li><p>This exhibit demonstrates the complex ways in which wires, switches, and bulbs can be interconnected, showing that electrical circuits can have multiple unconventional pathways.</p></li><li><p>Solving the puzzle requires a variety of problem-solving strategies. Visitors might employ a brute-force approach, testing every possible combination, or they might apply more sophisticated heuristic techniques to more efficiently navigate through the solution space.</p></li><li><p>The exhibit encourages strategic thinking, prompting visitors to observe, hypothesise, and test their theories about the connections within the circuit, enhancing their understanding of both electrical engineering principles and logical problem-solving.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Luminous Layers (Ignore)
  "Luminous Layers": {
    title: "LUMINOUS LAYERS",
    tag: "Unlock the Potential of Visual Combinations in Biotechnology",
    bannerImage: "",
    bannerImage2: "",
    keywords: ["Visualisation", "Transparent Layers", "Backlit Display"],
    scienceRelevancy:
      "This exhibit invites visitors to unleash their creativity and visualise potential combinations of various objects and organisms. Equipped with a backlit table and multiple transparent sheets, each printed with different images, visitors can layer these sheets to explore and discover how different combinations might appear.<br /><br />This interactive setup allows for a hands-on, creative approach, providing a glimpse into how different traits or features might combine in real-world applications.",
    keyTakeAway:
      "<ul><li><p>The exhibit utilises translucent materials printed on transparent sheets, which allows images to be visible even when stacked. This property is crucial for layering without losing the visibility of underlying layers.</p></li><li><p>A backlit table illuminates the sheets from below, ensuring that each layer remains distinct and clear. This backlighting is essential for enhancing the visibility of each combination, allowing for accurate and detailed observation.</p></li><li><p>By stacking different layers, visitors can experiment with and visualise the possibilities of combining traits or features. This hands-on activity not only educates about genetic and feature combinations but also sparks interest in the fields of biotechnology and bioengineering.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // ---------------------------INTRO GALLEY ENDS---------------------------
  // --------------------------TACTILE GALLEY STARTS--------------------------
  // IMAGES AND YT LINKS NOT GIVEN
  // Pressure Sensitive Wall
  "Pressure Sensitive Wall": {
    title: "PRESSURE SENSITIVE WALL",
    tag: "Capacitive sensors detect touch-illuminating lights in response.",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Pressure+Sensitive+Wall+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Pressure+Sensitive+Wall+2.webp",
    keywords: [
      "Interactive Technology",
      "RGB LED",
      "Touch-Sensitive",
      "Hexagonal Panels",
      "User Interaction",
      "Circuitry",
      "Design Innovation",
    ],
    scienceRelevancy:
      "Ideal for various settings, this exhibit offers an intuitive and dynamic experience. It invites users to physically alter the visual output of the panels, creating a communal, creative, and immersive environment.<br /><br />This exhibit utilizes Smart RGB Hexagon LED Lights to merge touch-sensitive technology with LED lighting. It features hexagonal panels with RGB LEDs that change in color and brightness when interacted with, demonstrating advanced interactive design and responsive environments.",
    keyTakeAway:
      "<ul><li><p>Encourages exploration of interactive design.</p></li><li><p>Highlights the integration of technology and art.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Interactive Illuminations
  "Interactive Illuminations": {
    title: "INTERACTIVE ILLUMINATIONS",
    tag: "Toggle switches to create a pixelated installation",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Switches+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Switches+2.webp",
    keywords: [
      "Electrical Circuits",
      "Interactive Art",
      "Electrical Switches",
      "Circuitry Education",
      "Hands-on Learning",
      "Art and Science Integration",
    ],
    scienceRelevancy:
      'This art installation allows viewers to create visual patterns and messages, blending tactile and visual engagement. It simplifies the concept of electrical circuits, making it accessible and engaging for all ages.<br /><br />"Interactive Illuminations" showcases the basics of electrical circuits. It features a panel of orange rocker switches, each connected to a small light bulb. Flipping a switch completes a circuit, lighting the bulb and offering an experiential understanding of electrical circuitry and the role of switches.',
    keyTakeAway:
      "<ul><li><p>Demonstrates electrical circuit operation.</p></li><li><p>Illustrates switch functionality in electrical flow.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Surface Spectrum
  "Surface Spectrum": {
    title: "SURFACE SPECTRUM",
    tag: "Demonstration of a polymer’s versatility through various 3D printed textures",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Surface+spectrum+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Surface+spectrum+2.webp",
    keywords: [
      "PLA",
      "Thermoplastic Polyester",
      "3D Printing",
      "Texture Variation",
      "Friction Levels",
      "Tactile Sensation",
      "Material Science",
    ],
    scienceRelevancy: `Visitors have the opportunity to touch PLA samples with varying textures and friction levels. This interactive exploration allows them to understand PLA's versatility in creating patterns, emphasizing material properties and tactile perception.<br /><br />"Surface Spectrum" delves into the properties of PLA, a thermoplastic polyester used in 3D printing. It demonstrates how heating and cooling PLA changes its texture and pattern, leading to a range of tactile sensations. This exhibit shows how different textures of PLA affect friction levels when touched, providing insight into material properties and skin interaction.`,
    keyTakeAway:
      "<ul><li><p>Demonstrates how surface texture influences friction.</p></li><li><p>Highlights the link between tactile sensation and material science.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // The Eco Simulator
  "The Eco Simulator": {
    title: "THE ECO SIMULATOR",
    tag: "Digital simulation of real-world textures",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Eco+Simulator1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Eco+Simulator1.webp",
    keywords: [
      "Sensory Technology",
      "Digital Simulation",
      "Haptic Feedback",
      "Touch Sensors",
      "Material Properties",
      "Interactive Exhibit",
      "Technological Immersion",
    ],
    scienceRelevancy:
      'In this exhibit, visitors engage with a digital canvas that transforms their touch, pressure, and movement into realistic sensations, providing an immersive and informative exploration into the realm of sensory technology.<br /><br />"The Eco Simulator" is at the forefront of sensory technology and digital simulation. The exhibit utilizes touch sensors and haptic feedback mechanisms, which are adept at detecting and responding to user interactions. These technological elements work together to simulate the physical properties of different materials, showcasing the capabilities of advanced programming in sensory simulation.',
    keyTakeAway:
      "<ul><li><p>Illustrates the use of sensory technology to simulate material properties.</p></li><li><p>Showcases the successful combination of technology with natural world simulations.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Texture Tiles
  "Texture Tiles": {
    title: "TEXTURE TILES",
    tag: "Understand friction and porosity in different materials through a graph representation",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Texture+Tiles1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Texture+Tiles2.webp",
    keywords: [
      "Material Properties",
      "Friction",
      "Porosity",
      "Texture Tiles",
      "Interactive Graph",
      "Tactile Sensation",
      "Educational Exhibit",
    ],
    scienceRelevancy:
      'This exhibit invites visitors to interact with a variety of materials, allowing them to feel the difference in touch sensation caused by varying friction and porosity levels. An essential feature of "Texture Tiles" is an interactive graph that visually maps the relationship between friction and porosity across different materials, providing a hands-on learning experience that blends educational insight with scientific exploration.<br /><br />"Texture Tiles" delves into the science of material properties, particularly friction and porosity. It explores how different materials vary in these properties, influenced by their surface texture and internal structure.',
    keyTakeAway:
      "<ul><li><p>Emphasizes the importance of friction and porosity in material properties.</p></li><li><p>Allows tactile exploration of various textures.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Van de Graaff Generator (Ignore)
  "Van de Graaff Generator": {
    title: "VAN DE GRAAFF GENERATOR",
    tag: "Demonstrates the power of static electricity",
    bannerImage: "",
    bannerImage2: "",
    keywords: [
      "Static Electricity",
      "Van de Graaff Generator",
      "Electron Movement",
      "Electric Charges",
      "Hands-on Learning",
      "Educational Exhibit",
      "Electrical Repulsion",
    ],
    scienceRelevancy:
      'In this interactive exhibit, visitors get a firsthand experience of static electricity. Touching the Van de Graaff generator, they witness the repulsion of like charges, as the accumulated static charge causes their hair to stand on end.<br /><br />The "Van de Graaff Generator" exhibit is a dynamic demonstration of static electricity, a phenomenon stemming from an imbalance of electric charges on a surface. This exhibit operates on electron movement, showcasing how contact between materials can lead to the transfer of electrons, thus creating positive and negative charges. The generator is specifically designed to accumulate a substantial static charge, which becomes evident when someone interacts with it.',
    keyTakeAway:
      "<ul><li><p>Demonstrates the principles of static electricity.</p></li><li><p>Provides a tactile experience of electrical repulsion.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Bed of Nails (Ignore)
  "Bed of Nails": {
    title: "BED OF NAILS",
    tag: "Pressure = Force / Area",
    bannerImage: "",
    bannerImage2: "",
    keywords: [
      "Pressure",
      "Surface Area",
      "Bed of Nails",
      "Physics Demonstration",
      "Force Distribution",
      "Educational Exhibit",
      "Interactive Learning",
    ],
    scienceRelevancy:
      'This exhibit begins with the demonstration of a single nail easily popping a balloon, highlighting how concentrated force on a small surface area leads to high pressure. In contrast, a balloon placed on a bed of nails does not pop under pressure, illustrating the concept of even pressure distribution. This helps visitors understand that objects, and even people, can withstand greater forces without damage when the pressure is evenly distributed.<br /><br />The "Bed of Nails" exhibit is a practical demonstration of the principle that surface area is inversely proportional to pressure. Using a bed of nails combined with a balloon, the exhibit visually and tangibly shows how spreading force over a larger surface area can significantly reduce the pressure exerted on any single point.',
    keyTakeAway:
      "<ul><li><p>Illustrates the inverse relationship between surface area and pressure.</p></li><li><p>Relates the concept to real-world situations and body mechanics.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Thermal Deceit (Ignore)
  "Thermal Deceit": {
    title: "THERMAL DECEIT",
    tag: "Peltier Cooler transfers heat from one side to another using electricity.",
    bannerImage: "",
    bannerImage2: "",
    keywords: [
      "Thermal Illusions",
      "Temperature Perception",
      "Sensory Experience",
      "Cognitive Dissonance",
      "Perceptual Conflict",
      "Mind-Body Interaction",
    ],
    scienceRelevancy:
      'In this exhibit, visitors encounter scenarios where objects contradict typical temperature expectations, leading to a striking perceptual conflict. This experience serves to highlight the intricate relationship between the mind and body in sensory perception.<br /><br />"Thermal Deceit" explores the intriguing world of thermal illusions, providing a unique perspective on how we perceive temperature. The exhibit delves into how our brain processes signals from skin receptors and how our expectations can significantly influence our perception of hot and cold. It stands as a testament to the complex ways in which our cognitive processes can shape our sensory experiences.',
    keyTakeAway:
      "<ul><li><p>Explores the concept of thermal perception and illusions.</p></li><li><p>Demonstrates how expectations can alter sensory experiences.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Bridge of Response
  "Bridge of Response": {
    title: "BRIDGE OF RESPONSE",
    tag: "An illusion that blurs reality by deceiving the brain’s body perception",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Bridge+of+Response+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Bridge+of+Response+2.webp",
    keywords: [
      "Artificial Hand Illusion",
      "Sensory Integration",
      "Rubber Hand",
      "Brain Perception",
      "Prosthetics",
      "Phantom Limb Pain",
      "Neuroscience",
    ],
    scienceRelevancy: `This interactive demonstration highlights the brain's ability to interpret and integrate sensory information, and its implications in areas such as prosthetic development and the treatment of phantom limb pain.<br /><br />The "Bridge of Response" provides an immersive exploration of the artificial hand illusion, a phenomenon that illustrates the brain's capacity to integrate visual and tactile information. The exhibit creates a sensory conflict by simultaneously stroking an artificial hand and the participant's real hand, leading the brain to recognize the artificial  hand as part of the body. This showcases the active role of the brain in constructing sensory experiences and adapting to new sensory inputs.`,
    keyTakeAway:
      "<ul><li><p>Demonstrates the brain's ability to integrate diverse sensory inputs.</p></li><li><p>Emphasizes the adaptability and plasticity of the brain in sensory experiences.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Thigmo Sprouts (Ignore)
  "Thigmo Sprouts": {
    title: "THIGMO SPROUTS",
    tag: "Biological adaptation of the Mimosa Pudica (Touch-Me-Not) plant, to tactile stimuli for protection",
    bannerImage: "",
    bannerImage2: "",
    keywords: [
      "Thigmonasty",
      "Mimosa pudica",
      "Sensitive Plants",
      "Tactile Sensitivity",
      "Biological Adaptation",
      "Interactive Learning",
      "Plant Defense Mechanism",
    ],
    scienceRelevancy: `Visitors interact with these plants, witnessing their instantaneous folding or drooping in response to touch, and later their unfolding. This hands-on experience not only demonstrates thigmonasty but also emphasizes the plant's reversible response as a sophisticated adaptation for survival.<br /><br />"Thigmo Sprouts" explores the unique biological adaptation of sensitive plants like Mimosa pudica, known as thigmonasty. This rapid and reversible movement in response to touch or mechanical stimulation serves as a defense mechanism, showcasing the plant's tactile sensitivity and active protection strategy.`,
    keyTakeAway:
      "<ul><li><p>Highlights plant's tactile sensitivity and defense mechanisms.</p></li><li><p>Provides an interactive experience of biological adaptation.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Pin Cushion Wall
  "Pin Cushion Wall": {
    title: "PIN CUSHION WALL",
    tag: "Each pin reacts to the force of touch",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Pin+Cushion+Wall+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Pin+cushion+wall+2.webp",
    keywords: [
      "Pin Art",
      "Tactile Canvas",
      "Pressure",
      "3D Impressions",
      "Force",
      "Spatial Awareness",
      "Interactive Exhibit",
    ],
    scienceRelevancy:
      'The interaction with the "Pin Cushion Wall" allows visitors to see and feel the immediate impact of their actions in real time. Each press creates a unique, three-dimensional relief, offering an engaging and creative way to understand the principles of pressure, form, and spatial awareness.<br /><br />"Pin Cushion Wall" is a tactile and visual exploration of pressure and form. This exhibit consists of a large canvas filled with movable pins that can be manipulated to create 3D impressions. When a visitor presses their hand or fingers against it, the pins move to mirror the shape, showcasing the physics of force and the spatial relationship of objects.',
    keyTakeAway:
      "<ul><li><p>Demonstrates the principles of force and spatial relation.</p></li><li><p>Encourages hands-on interaction to understand 3D form creation.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Action Mural Showcase
  "Action Mural Showcase": {
    title: "ACTION MURAL SHOWCASE",
    tag: "Empowering Words, Unleashing Action",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Pin+Cushion+Wall+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/TactileGallery/Pin+cushion+wall+2.webp",
    keywords: [
      "Action Mural Showcase",
      "Communication Dynamics",
      "Static Language",
      "Dynamic Action",
      "Touch and Cognition",
    ],
    scienceRelevancy:
      "Enter the Action Mural Showcase to animate static murals through touch. Explore the link between touch, action words, and art.<br /><br />Action Mural Showcase explores communication dynamics, transforming static language into dynamic action. Integrating art and technology, it highlights the interplay of touch and cognition.",
    keyTakeAway:
      "<ul><li><p>Demonstrates the symbiotic relationship between human interaction and artistic creation.</p></li><li><p>Illustrates the transformative power of touch in activating static images.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // --------------------------TACTILE GALLEY ENDS--------------------------
  // -X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-326.08
  // ------------------------ILLUSION GALLEY STARTS-------------------------
  // TAG LINE, IMAGES AND YT LINK NOT GIVEN
  // Aśrūni Ambigram
  "Asruni Ambigram": {
    title: "AŚRŪNI AMBIGRAM",
    tag: "Perceptual Artistry in Dynamic 3D Ambigrams",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/As%CC%81ru%CC%84ni+Ambigram1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/As%CC%81ru%CC%84ni+Ambigram2.webp",
    keywords: [
      "Optical Illusions",
      "Visual Perception",
      "3D Ambigrams",
      "Art and Psychology",
      "Perspective",
      "Cognitive Processing",
      "Pandemic Impact",
    ],
    scienceRelevancy: `The exhibit features 3D ambigrams that present a powerful message, reading as 'COVID' from one angle and 'Death' from another. This striking representation encapsulates the profound global impact of the pandemic.<br /><br />Distorted 3D ambigrams delve into the fascinating world of optical illusions and visual perception. These unique designs play with the brain's natural inclination to find recognizable patterns in ambiguous visual cues. This exhibit demonstrates how the same image can be perceived in multiple ways, altering its interpretation based on perspective and context, and effectively showcasing the complexities of visual processing.`,
    keyTakeAway:
      "<ul><li><p>Illustrates the brain's capacity to interpret complex visual stimuli.</p></li><li><p>Underlines the role of perspective in shaping visual perception.</p></li><li><p>Shows the fusion of art with cognitive psychology.</p></li><li><p>Reflects on how context influences perception, mirroring the psychological impact of the pandemic.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // 1000 Eyes Of Krodha
  "1000 Eyes Of Krodha": {
    title: "1000 EYES OF KRODHA",
    tag: "Exploring Multiple Reflections at the Parabolic Mirror Focus",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/1000+Eyes+Of+Krodha1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/1000+Eyes+Of+Krodha2.webp",
    keywords: [
      "Optics",
      "Parabolic Mirrors",
      "Light Reflection",
      "Focal Imagery",
      "Emotional Representation",
      "Visual Experience",
      "Physics in Art",
    ],
    scienceRelevancy: `The installation consists of 1,000 parabolic mirrors arranged to create a captivating visual effect. When a visitor stands at the designated focal point, their reflection is captured and multiplied across all mirrors. This provides an immersive experience, allowing visitors to see themselves in a unique, multiplied perspective that is both visually striking and emotionally resonant.<br /><br />This exhibit is rooted in the principles of optics, particularly how light behaves when interacting with parabolic mirrors. These mirrors are specially designed to reflect light towards a single focal point, effectively demonstrating the concept of focused imagery. The exhibit brings to life the theoretical aspects of optics and light behavior, making it an educational and visual spectacle.`,
    keyTakeAway:
      "<ul><li><p>Showcases the principles of light reflection and convergence through parabolic mirrors.</p></li><li><p>Demonstrates the practical application of optical physics in creating engaging visual experiences.</p></li><li><p>Symbolizes the intensity of emotions like anger, as the multiplied reflections represent how strong emotions can dominate our perception and overwhelm our viewpoint.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Anamorphic Anurāga
  "Anamorphic Anuraga": {
    title: "ANAMORPHIC ANURĀGA",
    tag: "Adjusting Views to Reveal Shapes in 3D Anamorphic Art",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Anamorphic+Anura%CC%84ga1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Anamorphic+Anura%CC%84ga2.webp",
    keywords: [
      "3D Anamorphic Art",
      "Perspective",
      "Visual Perception",
      "Distorted Projection",
      "Reflection",
      "Emotional Representation",
      "Perception and Art",
    ],
    scienceRelevancy: `Featuring 3D anamorphic sculptures of a cat, frog, and duck, this exhibit uniquely engages visitors. The sculptures initially appear distorted, but when viewed in the reflection of cylindrical mirrors, they reveal their true forms. This intriguing interplay invites the audience to experience the magic of perception and reflection, as each figure transforms from a chaotic shape into a clear and coherent form within the mirror.<br /><br />3D anamorphic art is an exploration into the science of perspective and visual perception. The underlying science involves an understanding of how our eyes and brain perceive depth and dimensions, especially in a two-dimensional space, and how this perception is altered to recognize these images as coherent 3D forms from certain angles.`,
    keyTakeAway:
      "<ul><li><p>Showcases the transformation of distorted 2D images into coherent 3D forms through perspective manipulation.</p></li><li><p>Highlights the ability of human perception to find order in seemingly chaotic visuals.</p></li><li><p>Mirrors the emotional experience of finding beauty in imperfection, akin to the sensations of love.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Fun Darpan
  "Fun Darpan": {
    title: "FUN DARPAN",
    tag: "Mirror Strips Blend Reflection and Symmetry for Playful Face-Swapping",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Fun+Darpan1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Fun+Darpan2.webp",
    keywords: [
      "Reflection",
      "Perspective",
      "Visual Perception",
      "Mirror Strips",
      "Image Reconstruction",
      "Playful Interaction",
      "Perceptual Processing",
    ],
    scienceRelevancy: `Participants in this exhibit engage with a series of horizontally aligned mirror strips. As they move and align with these mirrors, parts of their faces seem to interchange with each other, creating an amusing and visually intriguing effect. This playful interaction with reflections encourages visitors to explore and understand the power of alignment and positioning in altering visual perception.<br /><br />"Playful Reflections" is a captivating exploration of the principles of reflection and perspective, combined with the brain's remarkable ability to reconstruct images. When viewers encounter fragmented reflections in the mirror strips, their brains instinctively work to piece these partial images into a coherent whole, even when features appear swapped. This interactive process is a testament to the brain's sophisticated image processing and perceptual capabilities.`,
    keyTakeAway:
      "<ul><li><p>Highlights the science of reflection and perspective, emphasizing how positioning can change visual perception.</p></li><li><p>Demonstrates the fun aspect of manipulating reflections, showcasing how mirrors can ingeniously transform and merge images.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Maya Morphs
  "Maya Morphs": {
    title: "MAYA MORPHS",
    tag: "Optical Illusion of a Mirror Box Where Angles and Symmetry Morph Shapes",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Maya+Morphs1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Maya+Morphs2.webp",
    keywords: [
      "Reflection",
      "Geometry",
      "Optical Illusion",
      "45° Angle Mirrors",
      "Visual Perception",
      "Shapeshifting",
      "Mirror Box",
    ],
    scienceRelevancy: `Within the exhibit, a box containing sculptures becomes a dynamic visual experience. As visitors move around the box, the reflections of the sculptures shift and seem to change form. This effect is a result of the mirrors set at 45° angles, which manipulate the viewer's perspective and create the illusion of the sculptures morphing.<br /><br />The "Shapeshifting Mirror Box" is a fascinating demonstration of the principles of reflection and geometry. By utilizing mirrors set at a 45° angle, the exhibit creates a captivating optical illusion. These angled mirrors reflect images within the box in a way that varies with the viewer's perspective, illustrating the intriguing interaction of light with angled surfaces and the effects of geometric manipulation on visual perception.`,
    keyTakeAway:
      "<ul><li><p>Demonstrates the geometric concept that the angle of incidence is equal to the angle of reflection, and how this principle can be used to alter perceptions.</p></li><li><p>Emphasizes the brain's ability to interpret and piece together visual information to form coherent, albeit illusory, shapes.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Anantā Abyss
  "Ananta Abyss": {
    title: "ANANTĀ ABYSS",
    tag: "Endless Reflection and Depth Perception in Infinity Floor Illusion",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Ananta%CC%84+Abyss1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Ananta%CC%84+Abyss2.webp",
    keywords: [
      "Optical Illusion",
      "Infinity Mirror",
      "Depth Perception",
      "Reflective Properties",
      "Exaggerated Fear",
      "Anxiety Metaphor",
      "Visual Manipulation",
    ],
    scienceRelevancy: `Featuring a mirrored floor and wall, this exhibit gives visitors the sensation of walking over a boundless expanse, an experience that is both exhilarating and slightly unsettling. The illusion of infinite space can invoke a feeling akin to hovering over an immeasurable depth, potentially eliciting feelings of thrill or apprehension about the perceived risk of falling.<br /><br />The "Infinity Floor Mirrors" exhibit showcases an optical illusion created by a 2-way mirrors and LED lights, resulting in a perception of infinite depth. The illusion is crafted through the reflective properties of the mirrors, which, when positioned facing each other, create a continuous and seemingly unending vista. This setup is a clever manipulation of our depth perception, using the principles of reflection and light.`,
    keyTakeAway:
      "<ul><li><p>Demonstrates the power of visual cues in altering perception, particularly in creating a deceptive sense of depth.</p></li><li><p>Symbolizes how fear and anxiety can magnify perceived dangers, drawing a parallel to how the infinity mirror effect exaggerates spatial perception, distorting our understanding of reality.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Stillness Of Shānti
  "Stillness Of Shanti": {
    title: "STILLNESS OF SHĀNTI",
    tag: "Frozen Water Dance by Stroboscopy Merging Fluid Dynamics and Vision Persistence",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Stillness_shanti1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Stillness_shanti2.webp",
    keywords: [
      "Stroboscopy",
      "Fluid Dynamics",
      "Frozen Motion",
      "Strobe Lighting",
      "Water Droplets",
      "Visual Illusion",
      "Psychological State",
      "Perception",
    ],
    scienceRelevancy: `This exhibit features a fountain where water droplets seem to hang suspended in mid-air or move in a dramatically slowed manner. The strobe lights precisely timed with the water's movement transform a simple fountain into a mesmerizing visual spectacle. Visitors can witness the intricate dance of water droplets, providing a novel way to appreciate and understand the dynamics of fluids.<br /><br />The "Time-Frozen Water Dance" exhibit is a captivating display that combines the principles of stroboscopy and fluid dynamics. Stroboscopy involves the use of a strobe light flashing at the same frequency as the movement of water droplets. This synchrony creates the illusion of the water stream appearing stationary or moving in slow motion, providing a unique perspective on the behavior of fluids.`,
    keyTakeAway:
      "<ul><li><p>Demonstrates the effect of synchronizing strobe lighting with water droplets, creating the illusion of motion being frozen or slowed.</p></li><li><p>Offers an engaging way to understand fluid dynamics and the transformative impact of controlled lighting on visual perception.</p></li><li><p>Reflects a state of mental tranquility or peace, paralleling the experience of time slowing down or freezing, similar to the suspended water droplets in the stroboscopic fountain.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Surmount Siṃhāsana
  "Surmount Simhasana": {
    title: "SURMOUNT SIṂHĀSANA",
    tag: "Illusion of Size Distortion in Visual Perception",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Surmount_simhasana1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Surmount_simhasana2.webp",
    keywords: [
      "Forced Perspective",
      "Optical Illusion",
      "Perceived Size",
      "Distance Manipulation",
      "Triumph over Challenges",
      "Empowerment",
      "Perspective Art",
    ],
    scienceRelevancy: `This exhibit presents a uniquely distorted chair, constructed with its components placed in such a way that they appear different in size when viewed from a specific spot. When a visitor stands at this designated position, they appear significantly larger than the chair, which seems miniaturized due to the effect of forced perspective. This stark contrast in size powerfully alters the viewer's perception, illustrating how perspective can dramatically change our interpretation of size and space.<br /><br />The "Optical Throne of Triumph" employs the artistic technique of forced perspective, a method that manipulates our visual perception through the strategic scaling of objects and the viewer's specific point of view. This technique distorts the perceived size and distance of objects, creating an intriguing optical illusion that challenges our usual perceptions of space and scale.`,
    keyTakeAway:
      "<ul><li><p>Demonstrates the power of forced perspective in altering the perceived size and distance of objects.</p></li><li><p>Symbolizes the feeling of magnification and empowerment one experiences when confronting life's challenges bravely, akin to how the illusion makes the viewer appear larger and more dominant compared to the chair.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Jugupsā Genesis
  "Jugupsa Genesis": {
    title: "JUGUPSĀ GENESIS",
    tag: "3D Ambigram Transforms from Attraction to Disgust, Showcasing Sensory Psychology",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Jugupsa+Genesis1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Jugupsa+Genesis2.webp",
    keywords: [
      "Shadow Art",
      "3D Ambigram",
      "Light and Geometry",
      "Sensory Psychology",
      "Emotional Response",
      "Visual Perception",
      "Art and Science",
    ],
    scienceRelevancy: `This exhibit features a sculpture that, under specific lighting, casts the shadows of the letters "A," "B," and "C." These letters symbolize "Aversion," "Beloved," and "Context," exploring the concept of how environmental factors, like the setting of a dining experience, can sway our perception of food from appealing to unappetizing. The exhibit creatively merges ambience with taste perception, offering a unique exploration of the psychological impact of dining settings.<br /><br />The "Shadow Play Ambigram" explores the interaction of light with three-dimensional objects to create transformative two-dimensional shadows. This exhibit relies on the geometric arrangement of shapes and the physics of light, demonstrating how light travels in straight lines and forms silhouettes when it encounters an object. It emphasizes the fundamental principles of geometry and the behavior of light in creating visual effects.`,
    keyTakeAway:
      "<ul><li><p>Demonstrates how three-dimensional shapes can be crafted to produce specific two-dimensional shadows, showcasing artistic ingenuity.</p></li><li><p>Provides an understanding of the properties of light and shadow in art and optical science.</p></li><li><p>Highlights how visual appearances, like those created in the exhibit, can evoke emotions such as disgust, emphasizing the strong connection between visual cues and emotional responses.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Navarasa AR Wall
  "Navarasa AR Wall": {
    title: "NAVARASA AR WALL",
    tag: "Interactive AR Filters Bring Navarasa Emotions and Stories to Life",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Navarasa+AR+Wall1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/IllusionGallery/Navarasa+AR+Wall1.webp",
    keywords: [
      "Augmented Reality",
      "Navarasa",
      "Emotional States",
      "Interactive Experience",
      "AR Filters",
      "Technology and Emotion",
      "Immersive Exploration",
    ],
    scienceRelevancy: `This exhibit features an AR wall where visitors can see themselves with interactive filters that represent the Navarasas, the nine classical Indian emotions. Each emotion, from love and laughter to anger and fear, is artistically rendered, allowing visitors to explore and experience these diverse emotional states in a novel and interactive way.<br /><br />The Navarasa AR Experience harnesses Augmented Reality (AR) technology to create a unique, interactive showcase. AR combines real-world environments with digitally generated perceptual information, enhancing reality by overlaying digital content onto the physical world. This sophisticated technology uses algorithms, computer vision, and projection to blend virtual elements with real environments seamlessly, offering an engaging and immersive experience.`,
    keyTakeAway:
      "<ul><li><p>Showcases the innovative use of AR technology in creating immersive and interactive experiences.</p></li><li><p>Provides a platform for exploring and understanding a wide range of emotions through enhanced visual representations.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // ------------------------ILLUSION GALLEY ENDS--------------------------
  // -X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-
  // ------------------------DIGITAL GALLEY STARTS-------------------------
  // KEYWORDS, KEY TAKEWAYS, YT LINK AND IMAGES NOT GIVEN
  // Energy Mocap
  "Energy Mocap": {
    title: "ENERGY MOCAP",
    tag: "Step into our motion capture zone and ignite a virtual fire with your movements, bringing the screen to life!",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Energy+Mocap+1.png",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Energy+Mocap+2.png",
    keywords: [
      "Fire",
      "Pancha Mahabhuta",
      "Heat",
      "Energy",
      "Motion capture",
      "Visualization",
      "Digital processing",
    ],
    scienceRelevancy: `Deriving its roots from the concept of “ Pancha Mahabhuta” , fire is the heat that resides in the human body. A visualisation of fire that becomes energy  coming out of your body capturing your movement through motion capture technology.<br /><br />This exhibit uses a motion capture system to capture a user's movements. However, the final product is not a direct representation of those movements. Instead, it's a digitally processed image that may not reflect physical reality. This demonstrates how motion capture technology can create visualisations that differ from what we experience in the real world.`,
    keyTakeAway: `<ul><li><p>The Human Body and Energy: The concept of "fire" residing in the human body doesn't directly translate to scientific understanding. However, the body does generate heat through metabolic processes, which our nervous system regulates. This heat is a form of energy the body uses for various functions.</p></li><li><p>Motion Capture Technology and Representation: Motion capture uses cameras to track body movements and translate them into digital data. This exhibit highlights how motion capture can be used to create visualisations that may not directly correspond to physical reality. In this case, the "fire" is a visual representation of the user's movements, not an actual depiction of internal energy.</p></li></ul>`,
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Time Mocap
  "Time Mocap": {
    title: "TIME MOCAP",
    tag: "Immerse yourself in our interactive Time element, where your motions create captivating Slow mo animations on screen",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Time+Mocap+1.png",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Time+Mocap+2.JPG",
    keywords: [
      "Pancha Mahabhuta",
      "Body",
      "Time",
      "Visualization",
      "Motion Capture",
      "Webcam",
      "Perception",
      "Slow Motion",
    ],
    scienceRelevancy: `According to Pancha Mahabhuta theory the body is also made of 5 elements, but there is one more thing the body interacts with i.e. time hence this is a visualisation of timer that interacts with your body.<br /><br />This exhibit utilises motion capture technology with a webcam as the input source. The exhibit demonstrates how technology can manipulate our perception of time by capturing movement and displaying it in slow motion. This allows us to see our physical actions from a different perspective.`,
    keyTakeAway:
      "<ul><li><p>Time as a Fundamental Aspect: While the Pancha Mahabhuta theory focuses on elements, the exhibit cleverly highlights the concept of time as a fundamental aspect of our existence. In physics, time is considered a dimension we move through, alongside spatial dimensions. This exhibit showcases how our bodies interact and change within the flow of time.</p></li><li><p>Motion Capture and Time Manipulation: The exhibit uses motion capture technology to create a slow-motion animation effect. This demonstrates how technology can manipulate the perception of time. By capturing and displaying movements at a slower rate, we can gain a different perspective on our own physical actions.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Digital Doodle Dual
  "Digital Doodle Dual": {
    title: "DIGITAL DOODLE DUAL",
    tag: "Challenge your friends, family or partner in a duel of doodling skills. Bring out your inner child as you do so and let the A.I. decides who is fast accurate and fun",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Doodle+Dual+1.png",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Doodle+Dual+2.JPG",
    keywords: [
      "Doodle Recognition",
      "AI System",
      "Competition",
      "Separate Space",
      "Rounds",
      "Accuracy",
      "Speed",
      "Computer Science",
    ],
    scienceRelevancy: `A doodle recognition A.I. based system is the core of this exhibit. Both players compete in Separate space for 3 rounds having 3 different doodles to make. The one who draws fast and accurately after 3 rounds will be made the winner by the A.I. on the basics of Accuracy and how fast one can draw.<br /><br />This Exhibit Deals with the realm of Computer science. A model of artificial intelligence Software was trained on 1000s of Doodles made by real people. What it does is just compare it with those dataset and tells you how close you are to the given doodle as a question.`,
    keyTakeAway:
      "<ul><li><p>Machine Learning for Pattern Recognition: This exhibit utilises machine learning, a branch of AI, for doodle recognition. The AI is trained on a massive dataset of doodles, allowing it to analyse user-drawn shapes and compare them to known patterns.</p></li><li><p>AI for Evaluating Creativity: While the core functionality involves pattern recognition, the exhibit explores the potential of AI to judge aspects of creativity. Here, the AI considers both speed and accuracy, which are factors contributing to artistic expression within a defined challenge.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Rangolify
  Rangolify: {
    title: "RANGOLIFY",
    tag: "Create beautiful rangolis digitally with our param made Rangolify",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Rangolify+1.png",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Rangolify+2.png",
    keywords: [
      "Geometric",
      "Colorful",
      "Patterns",
      "Symmetry",
      "Digital",
      "Code",
      "Rangolis",
      "Design",
      "Principle",
    ],
    scienceRelevancy: `An exhibit made in-house using code to generate geometric and colourful patterns digitally. Using the principle of symmetry.<br /><br />Working on the design principle of symmetry which can be found in all the traditionally made simple rangolis of indian households. The principle of symmetry naturally attracts human eyes.`,
    keyTakeAway: `<ul><li><p>Algorithmic Generation of Symmetrical Patterns: This exhibit explores the use of code to create symmetrical patterns, a core concept in mathematics and computer science. Symmetrical patterns are found throughout nature and can be aesthetically pleasing due to their inherent order.</p></li><li><p>Digital Preservation of Cultural Art Forms: "Rangolify" demonstrates how digital tools can be used to create and share traditional art forms like Rangoli. This allows for the preservation and exploration of cultural heritage in a new, interactive way.</p></li></ul>`,
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Rangolify2.o
  "Rangolify2.o": {
    title: "RANGOLIFY2.O",
    tag: "Make your beautiful rangolis dance with you with our Rangolify 2.0!",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/rangolify2.o.jpg",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/rangolify2.o.jpg",
    keywords: [
      "Exhibit",
      "In-House",
      "Rangolify",
      "Illusion",
      "Rhythmic Motion",
      "Symmetric Patterns",
      "Mesmerising",
    ],
    scienceRelevancy: `An exhibit made in-house similar to rangolify but added the illusion of rhythmic motion in the symmetric patterns.<br /><br />This exhibit uses rhythmic movements to create mesmerising Rangoli patterns that blend symmetry with the illusion of motion, captivating our attention.`,
    keyTakeAway: `<ul><li><p>Visual Perception of Motion and Symmetry: This exhibit combines symmetry, a well-studied mathematical concept, with the illusion of motion. Our brains are wired to find symmetrical patterns pleasing, and movement can further enhance our attention and engagement.</p></li><li><p>Computational Generation of Dynamic Patterns: The Rangolify 2.0 exhibit showcases the use of computer algorithms to create dynamic and evolving Rangoli patterns. This demonstrates the capabilities of computational tools in generating visually interesting and engaging experiences.</p></li></ul>`,
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Hands-on Opera
  "Hands-on Opera": {
    title: "HANDS-ON OPERA",
    tag: "Control the rhythms of sound to create mesmerising music, your own personal opera !",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Hands-on+Opera+1.JPG",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/DigitalGallery/Hands-on+Opera+2.JPG",
    keywords: [
      "Augmented Reality",
      "Navarasa",
      "Emotional States",
      "Interactive Experience",
      "AR Filters",
      "Technology and Emotion",
      "Immersive Exploration",
    ],
    scienceRelevancy: `An exhibit that can create sounds using your Hand gestures and producing sounds and visuals according to the hand movements. It uses the Hand tracking technology and tracks the position and gives you sound according to it.<br /><br />Making the full use of Leap motion, a hardware that uses infrared technology. It has an infrared sensor that gives you depth hence providing you with a 3 dimension input data of your hand movement. Later on those data are made into amazing visuals and melodies according to the hand you've positioned.`,
    keyTakeAway:
      "<ul><li><p>Showcases the innovative use of AR technology in creating immersive and interactive experiences.</p></li><li><p>Provides a platform for exploring and understanding a wide range of emotions through enhanced visual representations.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // ------------------------DIGITAL GALLEY ENDS---------------------------
  // -X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-
  // ------------------------KINETIC GALLEY STARTS-------------------------
  // Water Drop Automata
  "Water Drop Automata": {
    title: "WATER DROP AUTOMATA",
    tag: "Mechanical Simulation of Water Drop Ripples",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Wooden+automata+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Wooden+automata+2.webp",
    keywords: [
      "Cams",
      "Rotational Motion",
      "Linear Motion",
      "Constant Phase Difference",
      "Mechanical Simulation",
      "Natural Phenomena",
    ],
    scienceRelevancy: `This exhibit features a hand-cranked wood automaton, designed to mimic the effect of a water drop. As you rotate the crank, you can see the concentric circles simulating the ripples caused by a water drop. Through the simple act of turning a handle, visitors are invited to engage directly with the mechanical principles that underpin natural phenomena, bridging the gap between the tangible and the abstract.`,
    keyTakeAway:
      "<ul><li><p>Cranks connected to cams of varying diameters move rods, causing circular pieces to move up and down.</p></li><li><p>Symmetric linear motion around the centre creates a water ripple effect, achieved through the cams' phase difference.</p></li><li><p>The design ensures a gradual, seamless connection of points equidistant from the centre during rotation.</p></li><li><p>Visual simulation of ripples demonstrates mechanical engineering's ability to replicate natural patterns.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Harmonograph
  Harmonograph: {
    title: "HARMONOGRAPH",
    tag: "Create and take home your unique pattern",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Harmonogrpah.jpg",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Harmonogrpah.jpg",
    keywords: [
      "Pendulums",
      "Chaos Theory",
      "Oscillatory Motion",
      "Rotatory Motion",
      "Mechanical Simulation",
      "Art in Motion",
    ],
    scienceRelevancy: `Dive into the fascinating world of the Harmonograph, a mesmerising exhibit where science meets art. At its core, the Harmonograph is a simple pendulum with a pen attached at the tip, elegantly designed to draw captivating designs on paper. As the pendulum swings back and forth, the pen traces out intricate patterns, each unique to the initial conditions set by you. How far you pull the pendulum from its centre and the force with which you release it are the architects of your design. By giving the pendulum multiple gentle pushes - an initial one and subsequent ones before it comes to a halt - you can influence the evolution of your artwork, making every pattern a personal signature of the moment.`,
    keyTakeAway:
      "<ul><li><p>Harmonograph illustrates principles of oscillatory (back-and-forth) and rotatory (circular) motion, key physics concepts.</p></li><li><p>Pendulums demonstrate oscillatory motion, aiming for equilibrium but creating patterns due to non-instantaneous movement.</p></li><li><p>The addition of rotatory motion introduces complexity and beauty, merging two types of motion harmoniously.</p></li><li><p>The artwork embodies Chaos Theory, emphasizing sensitive dependence on initial conditions and complex systems' deterministic yet unpredictable nature.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Walking Bicycle
  "Walking Bicycle": {
    title: "WALKING BICYCLE",
    tag: "A Mechanical Combination of Walking and Cycling",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Walking+Bicycle+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Walking+Bicycle+2.webp",
    keywords: [
      "Degree of freedom",
      "Mechanical Joints",
      "Forward Motion",
      "Innovative Locomotion",
      "Kinetic",
    ],
    scienceRelevancy: `Discover the unique "Walking Bicycle," an innovative exhibit that merges the concept of walking with cycling. This extraordinary bicycle features a traditional front wheel complemented by four mechanical hind legs, instead of the usual rear wheel. These legs, designed with simple mechanical joints strategically placed, ingeniously generate forward motion, captivating visitors with its blend of mechanics and locomotion.`,
    keyTakeAway:
      "<ul><li><p>Walking Bicycle legs crafted with two degrees of freedom for movement along two axes.</p></li><li><p>Design enables walking motion mimicry, with legs generating backward force against the ground.</p></li><li><p>This force propels the bicycle forward, showcasing the conversion of rotational motion into linear movement.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Slithering Symphony
  "Slithering Symphony": {
    title: "SLITHERING SYMPHONY",
    tag: "Mimicking the harmonious movement of a snake",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Slithering+Symphony+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Slithering+Symphony+2.webp",
    keywords: [
      "Cams",
      "Phase Angle",
      "Phase Difference",
      "Rotational Motion",
      "Translatory Motion",
      "Oscillatory Motion",
      "Mechanical Simulation",
      "Sine Wave",
    ],
    scienceRelevancy: `This exhibit presents an engaging mechanical simulation where, as you rotate the crank, beads come to life, mimicking the sinuous motion of a slithering snake. The intricate design and the precise arrangement of its components allow the beads to move in a fashion that closely resembles the natural, fluid motion of a snake moving across the ground.`,
    keyTakeAway:
      "<ul><li><p>Slithering Symphony uses cranks and cams in a non-symmetric, wave-like sequence for distinctive movement.</p></li><li><p>Arrangement introduces constant phase angle difference between cams, generating sine wave motion.</p></li><li><p>Sine wave motion translated into the oscillatory motion of beads, creating the snake-like slithering effect.</p></li><li><p>Demonstrates application of mechanical principles, translating rotational into oscillatory motion, captivating users.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Kinetic Square Wave (Ignore)
  "Kinetic Square Wave": {
    title: "KINETIC SQUARE WAVE",
    tag: "A mesmerizing interplay of two intertwining waves",
    bannerImage: "",
    bannerImage2: "",
    keywords: [
      "Kinetic Art",
      "Geometric Shapes",
      "Visual Illusion",
      "Wave Motion",
    ],
    scienceRelevancy: `The Kinetic Square Wave exhibit showcases an extraordinary visual phenomenon through the rotation of two intricate shapes in opposite directions. As you observe the exhibit, you will notice the borders of these shapes intersecting and diverging, creating the illusion of two waves seamlessly intertwining with each other. This captivating display not only demonstrates the beauty of motion but also invites you to ponder the complexity of simple geometric forms in action.`,
    keyTakeAway:
      "<ul><li><p>Irregular shapes set into motion create a visual effect of intertwining waves, thanks to precise engineering.</p></li><li><p>Shapes rotating in opposite directions periodically align and separate, forming a wave-like illusion.</p></li><li><p>Demonstrates how simple physical movements can produce complex visual patterns, offering insight into geometry, physics, and visual perception.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Kinetic Illusion
  "Kinetic Illusion": {
    title: "KINETIC ILLUSION",
    tag: "Blending motion and art to create an illusion",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Kinetic+Illusion+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Kinetic+Illusion+2.webp",
    keywords: [
      "Kinetic",
      "Illusion",
      "Art",
      "Open Belt Drive",
      "Closed Belt Drive",
      "Pulleys",
      "Symmetric Patterns",
      "Motion",
    ],
    scienceRelevancy: `This exhibit unveils a captivating display where two symmetric patterns take the stage, spinning in opposite directions. As you engage with the single-hand crank, these patterns begin their mesmerising dance, overlapping and blending in a visual symphony that tricks the eye. The magic behind their contrary rotations lies in the ingenious use of both open and cross (closed) belt drives, connecting each pattern to the crank in a way that ensures their synchronised yet opposing movements.`,
    keyTakeAway:
      "<ul><li><p>Utilizes pulleys connected by open and cross belt drives to transfer motion.</p></li><li><p>Open belt drive maintains rotation direction; cross belt drive reverses it, creating opposite movements.</p></li><li><p>Demonstrates mechanical principles while creating a captivating kinetic illusion.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Pulley Systems
  "Pulley Systems": {
    title: "PULLEY SYSTEMS",
    tag: "Easily Lift Weights with Different Pulley Combinations",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Pulley+systems+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Pulley+systems+2.webp",
    keywords: [
      "Pulleys",
      "Weight",
      "Effort",
      "Mechanical Advantage",
      "Conservation of Energy",
      "Interactive Exhibit",
    ],
    scienceRelevancy: `This interactive exhibit showcases four different pulley setups, each attached to a weight of 5 kg. Visitors are invited to pull the handle on each setup to personally experience the variance in effort required to lift the same weight. The design cleverly demonstrates how the ease of lifting changes with the combination and number of pulleys used. Notably, in setups where lifting is easier, the weight's rise is more gradual. Conversely, setups requiring more effort result in a higher lift of the weight with the same pull, illustrating the mechanical advantage and efficiency of different pulley systems.`,
    keyTakeAway:
      "<ul><li><p>The exhibit demonstrates pulley system principles, showing how combinations affect lifting effort.</p></li><li><p>Despite effort differences, work done remains constant, illustrating the energy conservation principle.</p></li><li><p>The trade-off between effort and distance is shown: easier lifting setups require longer pulls for the same height, showcasing the mechanical advantage of pulleys.</p></li><li><p>Fundamental physics of force, work, and energy conservation made tangible and interactive.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Mechanical Multiplier
  "Mechanical Multiplier": {
    title: "MECHANICAL MULTIPLIER",
    tag: "Elegantly multiply by sliding two mechanical arms",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Mechanical+multiplier+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Mechanical+multiplier+2.webp",
    keywords: [
      "Flat Hinge Mechanism",
      "Multiplication Tables",
      "Degree Of Motion",
      "Arms",
      "Interactive Learning",
      "Mechanical Computation",
    ],
    scienceRelevancy: `This exhibit showcases a remarkable application of mechanical engineering to perform multiplication using arms and joints. Visitors are invited to interact with the exhibit by sliding the knobs located beneath a panel. These knobs control two mechanical arms, each representing a number in the multiplication process. As participants select their numbers by sliding these arms along the x-axis, a viewing window moves across the xy plane, revealing the product of the chosen numbers through a series of numbers written on the panel. This intuitive and hands-on approach allows for an engaging exploration of basic arithmetic operations.`,
    keyTakeAway:
      "<ul><li><p>Mechanical Multiplier uses a sophisticated flat hinge mechanism for binary operations like multiplication and squaring.</p></li><li><p>Interactive design demystifies multiplication, making it engaging for all ages.</p></li><li><p>The dual slider mechanism allows the multiplication of numbers 1 to 12, demonstrating degrees of motion in mechanics.</p></li><li><p>User-guided arm movements dynamically represent multiplication, linking mechanical action with mathematical principles.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Digit Flipper
  "Digit Flipper": {
    title: "DIGIT FLIPPER",
    tag: "Counting numbers with just 7 segments",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Digital+Flipper+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Digital+Flipper+1.webp",
    keywords: [
      "Cams",
      "Followers",
      "Mechanical Engineering",
      "7-Segment Display",
      "Digit",
      "Rotational Motion",
      "Interactive Exhibit",
      "Hand-Cranked",
    ],
    scienceRelevancy: `Discover the charm of our hand-cranked mechanical 7-segment digit display at the Digit Flipper exhibit. As visitors rotate the handle clockwise, a captivating sight unfolds; digits count down in a cyclic manner. Each turn of the crank aligns segments to seamlessly form numbers, showcasing not just the beauty of craftsmanship but the elegance of motion itself. Engage directly with the mechanics of digital display in a way that bridges the gap between ancient mechanical principles and modern digital technology.`,
    keyTakeAway:
      "<ul><li><p>Digit Flipper uses 7 segments each powered by its own intricately designed, multi-lobed cam.</p></li><li><p>Cams interact with spring-loaded cam followers, flipping segments from horizontal to vertical positions as they rotate.</p></li><li><p>This movement allows the display to dynamically count and show numbers, offering a practical exploration of converting rotational to linear motion.</p></li><li><p>Demonstrates mechanical engineering principles in creating digital representations through physical mechanisms.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Hoberman Sphere
  "Hoberman Sphere": {
    title: "HOBERMAN SPHERE",
    tag: "An isokinetic structure showcasing the flexibility of it's size",
    bannerImage:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Hoberman+sphere+1.webp",
    bannerImage2:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/IndividualGalleryImages/KineticGallery/Hoberman+sphere+2.webp",
    keywords: [
      "Isokinetic",
      "Size",
      "Links",
      "Uniform",
      "Kinetic Sculpture",
      "Geodesic Dome",
      "Scissor-Like Joints",
    ],
    scienceRelevancy: `This exhibit features a mesmerising Hoberman Sphere, an embodiment of both art and science. As you pull the string connected to one point of the sphere, you witness the sphere uniformly increase in size. This interactive display captures the essence of kinetic energy and mechanical engineering, providing a visual treat as the sphere expands and contracts. The ability of the sphere to grow and fold down uniformly not only excites visitors but also ignites curiosity about the principles governing its movement.`,
    keyTakeAway:
      "<ul><li><p>The Hoberman Sphere exemplifies engineering elegance, similar to a geodesic dome, through its scissor-like joint action.</p></li><li><p>A single force application spreads efficiently across the structure, showcasing force distribution and mechanical efficiency.</p></li><li><p>Uniform expansion and contraction are enabled by the intricate link design, maintaining shape while allowing size changes.</p></li><li><p>Acts as a hands-on investigation of isokinetic mechanisms and structural dynamics, offering a captivating study in physics and engineering.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // Time Glider (Ignore)
  "Time Glider": {
    title: "TIME GLIDER",
    tag: "Time Unveiled by the Sliding of Frames",
    bannerImage: "",
    bannerImage2: "",
    keywords: [
      "Microprocessor",
      "Time Representation",
      "Sliding Frames",
      "Visual Timekeeping",
      "Mechanical Design",
    ],
    scienceRelevancy: `The "Time Glider" exhibit captivates visitors by transforming time into a visual spectacle. As you watch, sliding windows or frames meticulously move each minute across a vivid red background. This movement is no ordinary motion; each position of the frames reveals a digit, crafting the current time in a mesmerising display. It's a dynamic and engaging way to perceive the passage of each moment, blending art, technology, and the relentless march of time into one.`,
    keyTakeAway:
      "<ul><li><p>Time Glider centres on a microprocessor controlling frame movement, representing minutes of the day.</p></li><li><p>Frames' specific arrangement depicts time, blending technology with art to prompt reflection on time's nature.</p></li><li><p>Exhibits synergy between computer programming and mechanical design, highlighting human ingenuity's potential.</p></li></ul>",
    // ytLink:
    //   "https://Drive.Google.Com/Drive/U/1/Folders/1B7E1Yzfieggmxqsjxlugpl2Zblecujv5",
  },
  // ------------------------KINETIC GALLEY ENDS---------------------------
  // -X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-
};

export default ExhibitData;
