import React from 'react'

export default function Example() {
  return (
    <div className="example" id='example'>
      <h2>Desmos API Example</h2>
      <div></div>
    </div>
  )
}
