import React, { useEffect, useState, forwardRef } from "react";
import axios from "axios";
import {
  AspectRatio,
  Box,
  BoxProps,
  Container,
  Heading,
  Input,
  Stack,
  Text,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Progress,
  Image,
  useBreakpointValue,
  Icon,
  CircularProgress,
  CircularProgressLabel,
  Flex,
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useDropzone } from "react-dropzone";
import { FiImage } from "react-icons/fi";
import ParamNavbar from "../../components/Navbar";

const first = {
  rest: {
    rotate: "-15deg",
    scale: 0.95,
    x: "-50%",
    filter: "grayscale(80%)",
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    x: "-70%",
    scale: 1.1,
    rotate: "-20deg",
    filter: "grayscale(0%)",
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
};

const second = {
  rest: {
    rotate: "15deg",
    scale: 0.95,
    x: "50%",
    filter: "grayscale(80%)",
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    x: "70%",
    scale: 1.1,
    rotate: "20deg",
    filter: "grayscale(0%)",
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
};

const third = {
  rest: {
    scale: 1.1,
    filter: "grayscale(80%)",
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    scale: 1.3,
    filter: "grayscale(0%)",
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
};

const PreviewImage = forwardRef((props, ref) => (
  <Box
    bg="white"
    top="0"
    height="100%"
    width="100%"
    position="absolute"
    borderWidth="1px"
    borderStyle="solid"
    rounded="sm"
    borderColor="gray.400"
    as={motion.div}
    backgroundSize="cover"
    backgroundRepeat="no-repeat"
    backgroundPosition="center"
    {...props}
    ref={ref}
  />
));

const AvatarGenerator = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [generationCount, setGenerationCount] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  // Use responsive padding and width
  const cardWidth = useBreakpointValue({
    base: "90%",
    sm: "80%",
    md: "60%",
    lg: "40%",
    xl: "30%",
  });
 
  const inputHeight = useBreakpointValue({
    base: "40px",
    sm: "45px",
    md: "50px",
    lg: "55px",
    xl: "60px",
  });
  const cardHeight = useBreakpointValue({
    base: "auto",
    sm: "auto",
    md: "auto",
    lg: "auto",
    xl: "auto",
  });
  const imageSize = useBreakpointValue({
    base: "200px",
    sm: "250px",
    md: "300px",
    lg: "350px",
    xl: "400px",
  });
  const dropzoneSize = useBreakpointValue({
    base: "150px",
    sm: "180px",
    md: "200px",
    lg: "220px",
    xl: "240px",
  });
   
  useEffect(() => {
    // Retrieve the generation count from localStorage
    const count = localStorage.getItem("generationCount");
    if (count) {
      setGenerationCount(parseInt(count));
    }
  }, []);

  const handleFileChange = async (acceptedFiles) => {
    setIsUploading(true);
    setSelectedFile(acceptedFiles[0]);

    // Simulate file upload process
    setTimeout(() => {
      setIsUploading(false);
    }, 2000); // Adjust the timeout as needed
  };

  const handleGenerate = async () => {
    if (generationCount >= 3) {
      alert("You have reached the maximum number of image generations (3).");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    setIsGenerating(true);
    setProgress(10); // Initial progress

    // Simulate progress update over 60 seconds
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 90) {
          clearInterval(interval);
          return 90;
        }
        return oldProgress + 1.33; // Incremental progress
      });
    }, 1000); // Adjust the interval as needed

    try {
      const response = await axios.post(
        "https://u10dteccv0.execute-api.ap-south-1.amazonaws.com/dev/describe",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response)
      setProgress(100); // Final progress when image is received
      setIsGenerating(false);
      setImageUrl(response.data.imageUrl);

      // Update the generation count
      const newCount = generationCount + 1;
      setGenerationCount(newCount);
      localStorage.setItem("generationCount", newCount);
    } catch (error) {
      console.error("Error generating avatar:", error);
      setIsGenerating(false);
      setProgress(0);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `https://u10dteccv0.execute-api.ap-south-1.amazonaws.com/dev/download?url=${encodeURIComponent(
      imageUrl
    )}`;
    link.setAttribute("download", "generated_avatar.png"); // Specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    console.log(imageUrl);
  }, [imageUrl]);

  const controls = useAnimation();
  const startAnimation = () => controls.start("hover");
  const stopAnimation = () => controls.stop();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileChange,
    multiple: false,
  });

  return (
    <>
    <Box>   <ParamNavbar/></Box>
   
      <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      p={4}
    >

      <Card width={cardWidth} height={cardHeight} bg="#232227" boxShadow="lg">
        <CardHeader>
          <Text fontSize="xl" fontWeight="bold" color="white" textAlign="center">
            Science Avatar Generator
          </Text>
        </CardHeader>
        <CardBody height={cardHeight}>
          <Stack spacing={4} alignItems="center">
            {!isGenerating && !imageUrl && (
              <>
                <Container my="2">
                  <AspectRatio width="100%" ratio={1}>
                    <Box
                      color="white"
                      borderStyle="dashed"
                      borderColor="#929195"
                      borderWidth="2px"
                      rounded="md"
                      shadow="sm"
                      role="group"
                      transition="all 150ms ease-in-out"
                      _hover={{
                        shadow: "md",
                      }}
                      as={motion.div}
                      initial="rest"
                      animate="rest"
                      width={dropzoneSize}
                      height={dropzoneSize}
                      whileHover="hover"
                      {...getRootProps()}
                      
                    >
                      <Box position="relative" height="100%" width="100%">
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          height="100%"
                          width="100%"
                          display="flex"
                          flexDirection="column"
                        >
                          <Stack
                            height="100%"
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justify="center"
                            spacing="4"
                          >
                            <Box height="16" width="12" position="relative" display="flex" justifyContent="space-around">
                              <PreviewImage
                                variants={first}
                                backgroundImage="url('https://img.freepik.com/premium-photo/ai-human-futuristic-code-digital-brain-avatar-concept-technology-science-virtual_163305-235838.jpg')"
                              />
                              <PreviewImage
                                variants={second}
                                backgroundImage="url('https://w0.peakpx.com/wallpaper/556/753/HD-wallpaper-profile-cyborg-cyberpunk-robot-face-makeup-eyeshadow-lipstick-blue-science-fiction-portrait-gynoid.jpg')"
                              />
                              <PreviewImage
                                variants={third}
                                backgroundImage={`url("https://img.freepik.com/premium-photo/avatar-scientists-lab-conducting-experiments-ai-generated_731790-16759.jpg")`}
                              />
                            </Box>
                            <Stack p="8" textAlign="center" spacing="1">
                              <Heading
                                fontSize="lg"
                                color="white"
                                fontWeight="bold"
                              >
                                Drop images here
                              </Heading>
                              <Text fontWeight="light">or click to upload</Text>
                              {selectedFile && (
                                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                  <Icon as={FiImage} color="gray.500" mr={2} />
                                  <Text color="gray.500">
                                    {selectedFile.name}
                                  </Text>
                                </Box>
                              )}
                            </Stack>
                          </Stack>
                        </Box>
                        <Input
                          {...getInputProps()}
                          type="file"
                          height="100%"
                          width="100%"
                          position="absolute"
                          top="0"
                          left="0"
                          opacity="0"
                          aria-hidden="true"
                          accept="image/*"
                          onDragEnter={startAnimation}
                          onDragLeave={stopAnimation}
                        />
                      </Box>
                    </Box>
                  </AspectRatio>
                </Container>

                {isUploading && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress
                      isIndeterminate
                      color="#fec800"
                      size="24px"
                    >
                      <CircularProgressLabel></CircularProgressLabel>
                    </CircularProgress>
                  </Box>
                )}
                <Button
                  bg="#fec800"
                  color="black"
                  onClick={handleGenerate}
                  isDisabled={!selectedFile || isGenerating || isUploading}
                  mt={4}
                >
                  Generate Avatar
                </Button>
              </>
            )}
            {isGenerating && (
              <Box width="100%">
                <Progress hasStripe value={progress} size="lg" colorScheme="green" />
                <Text textAlign="center" color="white" mt={2}>
                  Generating Avatar... {Math.round(progress)}%
                </Text>
              </Box>
            )}
            {imageUrl && (
              <>
                <Image
                  src={imageUrl}
                  alt="Generated Avatar"
                  boxSize={imageSize}
                  objectFit="contain"
                />
                <Button colorScheme="green" onClick={handleDownload}>
                  Download Image
                </Button>
              </>
            )}
          </Stack>
        </CardBody>
        <CardFooter color="white">
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            gap={2}
            p={2}
            width="100%"
          >
            <Text>Generations remaining: {3 - generationCount}</Text>
          </Box>
        </CardFooter>
      </Card>
    </Box>
    </>
    
  );
};

export default AvatarGenerator;






