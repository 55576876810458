import React from 'react'
import ParamNavbar from '../../components/Navbar'
import Content from '../../components/gaminZone/Content'
import "./ElementGames.css";
import Footer from '../../components/footer/Footer';

export const ElementGames = () => {
  return (
    <div className='eleGames'>
    <ParamNavbar />
    <Content />
    <Footer />
    </div>
  )
}
