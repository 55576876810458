const fieldColors = [
    '#878fb2',
    '#2a484f',
    '#2a1e54',
    '#0e220e',
    '#203b3e',
    '#c1cce0',
    '#5b9cab',
    '#ffffff',
    '#3f7583',
    '#b4c8d5',
    '#d4dde3',
    '#ffffff',
    '#ffffff',
    '#0e5477',
    '#bbcdda',
    '#e5e5ed',
    '#eef3ef',
    '#6d657a',
    '#b3bdc3',
    '#ede5c7',
    '#b7846e',
    '#9ea77a',
    '#53666e',
    '#4d374d',
    '#45607e',
    '#404f4c',
    '#005370',
    '#083a72',
    '#2a5362',
    '#f3e9e3',
    '#f0f0fc',
    '#ffffff',
    '#7e552c',
    '#6f3a6f',
    '#e7f6f5',
    '#11325d',
    '#35565c',
    '#3d3f55',
    '#583f57',
    '#faf1eb',
    '#f5f2f4',
    '#d0dcdf',
    '#034a82',
    '#293755',
    '#1b2d35',
    '#1d3462',
    '#ecfbfb',
    '#16284b',
    '#000000',
    '#722835',
    '#e0ebf0',
    '#f7fdfb',
    '#e4e2dd',
    // ... other colors
  ];

  export default fieldColors;