const FAQs = [
    {
      question: "What is Parsec - Jayanagar?",
      answer: "Parsec-Jayanagar is an interactive science experience centre in Bangalore that inspires curiosity in various scientific disciplines."
    },
    {
      question: "Who can visit Parsec - Jayanagar?",
      answer: "The centre welcomes visitors of all ages, including students, corporate groups, and individuals interested in science."
    },
    {
      question: "Can corporate groups visit?",
      answer: "Yes, corporate groups can engage in team-building activities and immersive learning experiences focused on science and technology."
    },
    {
      question: "What can visitors expect to find at Parsec-Jayanagar?",
      answer: "Visitors can explore hands-on exhibits, immersive experiences, and engaging activities across 6 galleries."
    },
    {
      question: "How can schools and colleges benefit from visiting?",
      answer: "Schools and colleges can reinforce scientific concepts and spark curiosity among students through interactive learning experiences."
    },
    {
      question: "Are there special events hosted at Parsec-Jayanagar?",
      answer: "Yes, the centre hosts workshops, lectures, and events featuring experts from various scientific fields."
    },
    {
      question: "Is photography allowed inside?",
      answer: "Yes, personal photography is permitted, but flash photography and videography are restricted in certain areas."
    },
    {
      question: "How long does the average visit last?",
      answer: "On average, visitors spend 1 to 1.5 hours exploring the exhibits and participating in activities."
    },
    {
      question: "Do you offer guided tours?",
      answer: "Yes, guided tours are available for groups."
    },
    {
      question: "Is the centre accessible for people with disabilities?",
      answer: "While not wheelchair accessible, accommodations can be discussed for special needs."
    },
    {
      question: "Is there a cafeteria available?",
      answer: "No on-site cafeteria, but dining options are nearby."
    },
    {
      question: "Can visitors bring outside food?",
      answer: "No, outside food and beverages are not allowed."
    },
    {
      question: "Is parking available?",
      answer: "No on-site parking, but public parking is nearby."
    },
    {
      question: "Can private events like birthday parties be hosted?",
      answer: "No, private events are not hosted at the centre."
    },
    {
      question: "Can bookings be cancelled or rescheduled?",
      answer: "Yes, one week of notice is required to cancel or reschedule."
    },
    {
      question: "Is the centre suitable for children of all ages?",
      answer: "Yes, it caters to visitors of all ages."
    },
    {
      question: "Are there restrictions on photography?",
      answer: "Flash photography and videography may be restricted in certain areas."
    },
    {
      question: "Is there a contact number for booking inquiries?",
      answer: "Yes, call +91 73385 80197 for booking inquiries."
    }
  ];
  
  export default FAQs;