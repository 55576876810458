const cardData = [
  {
    id: "intro",
    bannerUrl:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/gallery-images/intro.webp",
    linkURL: "/parsec/intro-gallery",
    buttonText: "INTRO GALLERY",
    galleryDescription:
      "Spark wonder and bend realities with interactive exhibits that ignite imagination.",
  },
  {
    id: "kinetic",
    bannerUrl:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/gallery-images/kinetic.webp",
    linkURL: "/parsec/kinetic-gallery",
    buttonText: "KINETIC GALLERY",
    galleryDescription:
      "Witness physics pirouette in a mesmerising ballet of motion and machines.",
  },
  {
    id: "tactile",
    bannerUrl:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/gallery-images/tactile1.webp",
    linkType: "internal",
    linkURL: "/parsec/tactile-gallery",
    buttonText: "TACTILE GALLERY",
    galleryDescription:
      "Unravel the universe’s secrets, hands-on, through exhibits that tickle your senses.",
  },
  {
    id: "digital",
    bannerUrl:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/gallery-images/digital1.webp",
    linkURL: "/parsec/digital-gallery",
    buttonText: "DIGITAL GALLERY",
    galleryDescription:
      "Dive into a virtual wonderland where stories dance with data.",
  },
  {
    id: "cardboard",
    bannerUrl:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/gallery-images/cardboard1.webp",
    linkURL: "/parsec/cardborad-gallery",
    buttonText: "CARDBOARD GALLERY",
    galleryDescription:
      "Unleash your inner architect in a playful playground of recycled marvels.",
  },
  {
    id: "illusion",
    bannerUrl:
      "https://digitalassestbucket.s3.amazonaws.com/IncityGallery/gallery-images/illusion1.webp",
    linkURL: "/parsec/illusion-gallery",
    buttonText: "ILLUSION GALLERY",
    galleryDescription:
      "Explore the science of emotions, where laughter rings and fears dissolve in a journey of 9 human sentiments.",
  },
  
];

export default cardData;
