import React, { useState, useEffect } from "react";
import ParsecNav from "../ParsecNav";
import "./Highlights.css";
import { Link, useParams } from "react-router-dom";
import ParsecFooter from "../../footer/ParsecFooter";

const bookMyShowUrl =
  "https://in.bookmyshow.com/activities/parsec-jayanagar/ET00383059?webview=true";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Function to convert month number to month name
const getMonthName = (monthNumber) => {
  return monthNames[monthNumber];
};

// extractDate function to return an object with day and monthName
const extractDateParts = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0"); // Ensure day is two digits
  const monthNumber = date.getMonth(); // Month number
  const monthName = getMonthName(monthNumber); // Convert month number to month name
  return { day, monthName };
};

// Function to convert 24-hour time to 12-hour format and return individual parts
const convertToTimeParts = (time) => {
  let [hours, minutes] = time.split(":"); // Convert hours and minutes to numbers
  const period = Number(hours) >= 12 ? "PM" : "AM";
  hours = Number(hours) % 12 || 12; // Convert 0 to 12 for 12-hour format

  // console.log(hours, minutes, period);

  // Return individual time parts
  return {
    hours,
    minutes,
    period,
  };
};

export default function Highlights() {
  let { id } = useParams();
  const [highlights, setHighlights] = useState(null);
  const [event, setEvent] = useState(null);
  const [date, setDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [time, setTime] = useState(null);
  const [timePeriod, setTimePeriod] = useState(null);
  const screenWidth = window.innerWidth;

  const redirectToBookMyShow = (url) => {
    const destinationUrl = url || bookMyShowUrl; // Use the provided URL or fall back to bookMyShowUrl
    window.open(destinationUrl, "_blank").focus();
  };

  // SCROLL TO TOP
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  });

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      signal: signal,
    };

    // Function to fetch data
    const fetchData = async (url) => {
      try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      } catch (error) {
        console.error("Fetch error:", error);
        // Handle error state here
      }
    };

    // Use Promise.all for parallel fetching
    Promise.all([
      fetchData(`${process.env.REACT_APP_API_ENDPOINT}/latestThree`),
      fetchData(`${process.env.REACT_APP_API_ENDPOINT}/fetchbyid/${id}`),
    ]).then(([highlightsResult, eventResult]) => {
      if (highlightsResult) setHighlights(highlightsResult);
      if (eventResult) setEvent(eventResult.data);
      // Handle loading state here
    });

    // Cleanup function to abort fetches on component unmount
    return () => {
      abortController.abort();
    };
  }, [id]);

  useEffect(() => {
    if (event) {
      const { day, monthName } = extractDateParts(event.fromDate);
      const { hours, minutes, period } = convertToTimeParts(event.time);
      setDate(day);
      setMonth(monthName);
      const time = hours + ":" + minutes;
      setTime(time);
      setTimePeriod(period);
    }
  }, [event]);

  return (
    <div className="highlights">
      <ParsecNav />
      <div className="highlightContent">
        {event && (
          <div className="highlightContentContainer">
            <div className="about">
              <h2>ABOUT</h2>
              <p>{event.description}</p>
            </div>
            <div
              className="dateNTime"
              style={{ backgroundImage: `url(${event.photoPortrait})` }}
            >
              {screenWidth > 450 ? (
                <div className="dateNTimeContainer">
                  <div className="dateNTimeContent">
                    <h2 style={{ textTransform: "uppercase" }}>
                      {event.title}
                    </h2>
                    <p style={{ opacity: ".5" }}>{event.tagline}</p>
                  </div>
                  <div className="dateTime">
                    <div className="date">
                      <h2 style={{ textAlign: "center" }}>{date}</h2>
                      <p style={{ opacity: ".5", textTransform: "uppercase" }}>
                        {month}
                      </p>
                    </div>
                    <div className="time" style={{ alignItems: "baseline" }}>
                      <h2 style={{ height: "fit-content" }}>{time}</h2>
                      <p style={{ height: "fit-content", opacity: ".5" }}>
                        {timePeriod}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bookTicket"
                    onClick={() => redirectToBookMyShow(null)}
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    Book Tickets
                  </div>
                </div>
              ) : (
                <div className="dateNTimeContainer">
                  <div className="dateTime">
                    <div className="date">
                      <h2 style={{ textAlign: "center" }}>{date}</h2>
                      <p style={{ opacity: ".5", textTransform: "uppercase" }}>
                        {month}
                      </p>
                    </div>
                    <div className="time" style={{ alignItems: "baseline" }}>
                      <h2 style={{ height: "fit-content" }}>{time}</h2>
                      <p style={{ height: "fit-content", opacity: ".5" }}>
                        {timePeriod}
                      </p>
                    </div>
                  </div>
                  <div className="dateNTimeContent">
                    <h2 style={{ textTransform: "uppercase" }}>
                      {event.title}
                    </h2>
                    <p style={{ opacity: ".5" }}>{event.tagline}</p>
                  </div>
                  <div
                    className="bookTicket"
                    onClick={() =>
                      redirectToBookMyShow(highlights.bookMyShowUrl)
                    }
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    Book Tickets
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {highlights?.length > 1 && (
          <div className="otherHighlights">
            <h2>ALSO CHECK OUT</h2>
            <div className="highlightData">
              {highlights &&
                highlights.map((highlight, index) => {
                  if (highlight._id !== id) {
                    return (
                      <div
                        key={index}
                        className="highlightCard"
                        style={{
                          backgroundImage: `url(${highlight.photoVideo})`,
                        }}
                      >
                        <Link to={`/parsec/highlights/${highlight._id}`}>
                          <div className="highlightCardContent">
                            <h2 style={{ textTransform: "uppercase" }}>
                              {highlight.title}
                            </h2>
                            <p>
                              {
                                highlight.tagline
                                  .split(" ") // Split the description into words
                                  .slice(0, 10) // Take the first 20 words
                                  .join(" ") // Join them back into a string
                              }
                              <span>...See more</span>
                            </p>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        )}
      </div>
      <ParsecFooter />
    </div>
  );
}
