// paragraphsData.js
const screenWidth = window.innerWidth;
const paragraphsData = [
    {
      id: 1,
      paragraphs: [
        `${screenWidth <= 450 ? "This anamorphic art, initially unappealing, unfolds its hidden beauty when viewed through the lens of love, revealing perfection in imperfection." : "The art here might not catch your eye at first glance, appearing less than visually appealing. However, step closer and experience the world through the eyes of love. Discover how this art piece, initially unusual-looking, unfolds its hidden beauty and meaning, revealing how love finds perfection in imperfection." }`,
        "In love's illusion, imperfections gleam with perfect beauty, vibrant and meaningful."
      ],
    },
    {
      id: 2,
      paragraphs: [
        `${screenWidth <= 450 ?"Step into an exhibit that, while seemingly ordinary at first, is a realm of joy and laughter. Where curved and distorted reflections bring simple things come alive with whimsical charm." : "At first glance, the exhibit here may appear ordinary but step into this space where joy and laughter reign supreme. Discover how a simple thing comes alive, transforming everyday moments into experiences infused with whimsical charm and delightful laughter"}`,
        "In the laughter's light, life's troubles fade, casting a joyful illusion of endless delight."
      ],
    },
    {
      id: 3,
      paragraphs: [
        `${screenWidth <= 450 ?"The distorted 3D ambigrams here, initially appearing as simple shapes, embody a grim reality, transforming into stark representations of grief as you step closer." : "The sculpture here may initially present as simple shapes, yet it embodies a deeper, more grim reality. Step into this space and experience the transformation of sorrow, where these forms become stark representations of grief, offering a glimpse into the heavier, often unspoken narratives of life."}`,
        "In sadness’ grip, past sorrows reappear as present shades, and life’s simple tasks turn into heavy, heart-laden crusades."
      ],
    },
    {
      id: 4,
      paragraphs: [
        `${screenWidth <= 450 ?"Observe a parabolic mirror, a poignant illustration of how anger distorts and sharpens our viewpoint, mirroring the concentrated intensity of this emotion." : "The exhibit may first strike you as chaotic and intense, a reflection of the turbulent nature of anger. As you immerse yourself in this domain, experience how anger narrows and intensifies our focus. "}`,
        "In anger’s blaze, eyes only see what fuels their fire, and time distorts, racing or crawling in furious ire."
      ],
    },
    {
      id: 5,
      paragraphs: [
        `${screenWidth <= 450 ?"Wear a crown, capture the royal throne and feel like a conqueror with this exhibit that employs forced perspective and clever designs." : "Come experience Heroism and how it alters our perception of size, making the daunting challenges appear manageable, as you symbolically conquer the throne and don the crown of a hero."}`,
        "In the heart of courage, we tower tall, making life’s greatest challenges seem small."
      ],
    },
    {
      id: 6,
      paragraphs: [
        `${screenWidth <= 450 ?"The path ahead, seemingly short, transforms into an infinite expanse in this exhibit with clever placement of mirrors. Step onto it and experience an illusion of heightened danger." : "The path before you may seem just a few steps long, but here, it transforms into an expanse of infinite depth. Step onto it and experience the illusion of heightened danger, where fear magnifies a simple walk into a journey across an endless chasm."}`,
        "In fear’s shadow, harmless things loom as threats dire, magnifying terror, a mind’s exaggerated, frightful mire."
      ],
    },
    {
      id: 7,
      paragraphs: [
        `${screenWidth <= 450 ?"Explore the intriguing interplay between visuals and olfactory senses, demonstrating how unpleasant sight can drastically alter our perception of taste and smell." : "Discover the powerful interplay of visuals and olfactory senses, as they transform a pleasant aroma into an experience of repulsion and disgust."}`,
        "In the darkness of disgust, senses betray, as harmless tastes grow harsh and mundane views dismay."
      ],
    },
    {
      id: 8,
      paragraphs: [
        `${screenWidth <= 450 ?"In this space, seemingly simple shapes transform through illusions, awakening a sense of profound wonder. Marvel at the clever use of mirrors that create enchanting visuals." : "The shapes here might appear simple at first but they become a source of endless fascination. Step into this space and experience an illusion, where ordinary forms transform and awaken a profound sense of wonder, revealing the extraordinary in the ordinary."}`,
        "Through wonder’s eyes, simple things shine anew, everyday moments dressed in enchanting hues."
      ],
    },
    {
      id: 9,
      paragraphs: [
        `${screenWidth <= 450 ?"Experience the stroboscopic water display, a mesmerizing visual that reflects the tranquil effect of peace on our sense of time." : "The water drops here might seem to fall in a typical, unending cascade, but step in, take a deep breath and watch them transform as the pace of falling water appears to slow down or even pause, mirroring the tranquil stillness that peace brings to our sense of time."}`,
        "In peace’s calm, time’s flow turns gentle and still, crafting an illusion where moments linger at will."
      ],
    },
  ];
  
  export default paragraphsData;
  