import React, { forwardRef } from "react";

const NavrasaField = forwardRef((props, ref) => {
  const { id, paragraphs } = props;
  const screenWidth = window.innerWidth;
  const basePath = `https://digitalassestbucket.s3.amazonaws.com/Navrasa`;
  const pathPrefix = screenWidth <= 450 ? 'M' : 'D';

  // Generate paths dynamically
  const paths = {
    background: `${basePath}/${pathPrefix}Background/Bg${id}.svg`,
    image: `${basePath}/${pathPrefix}Image/Img${id}.svg`,
    title: `${basePath}/${pathPrefix}Title/Tit${id}.svg`,
  };
  return (
    // Attach the forwarded ref to a DOM element
    <div className="navarasa-field" id={`field-${id}`} ref={ref}>
      <div
        className="background"
        style={{ backgroundImage: `url('${paths.background}')` }}
      >
        <div className="content">
          <div
            className="field-image"
            style={{ backgroundImage: `url('${paths.image}')` }}
          />
          <div
            className="field-title"
            style={{ backgroundImage: `url('${paths.title}')` }}
          />
          <div
            className="field-text"
            // style={{ backgroundImage: `url('${paths.text}')` }}
          >
            <p className="para1">{paragraphs[0]}</p>
            <div className="flex flex-end w-100">
              <div className="flex items-center w-50">
                <p className="para2">{paragraphs[1]}</p>
              </div>    
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NavrasaField;
