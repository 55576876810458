import React from "react";

export default function MContent() {
  return (
    <div className="mContent">
      <div>
        <span>Note :</span>
        <p>
          Thank you for your interest in APJ Abdul Kalam's Application. Currently,
          this application is optimised for desktop and laptop use only. We're
          working hard to provide a mobile-friendly version in the future.
          Please access the application on a desktop and laptop device for the
          best experience.We appreciate your understanding and patience.
        </p>
      </div>
    </div>
  );
}
