const textData = [
    {
      heading: "Big Bang",
      info: "The explosive event marking the universe's origin, approximately 13.8 billion years ago."
    },
    {
      heading: "Formation of Solar System",
      info: "About 4.6 billion years ago, our Solar System formed from a vast molecular cloud."
    },
    {
      heading: "Cooling of Earth",
      info: "Over billions of years, the molten Earth cooled, forming its solid crust and enabling life."
    },
    {
      heading: "First Life - First cell",
      info: "Roughly 3.5 billion years ago, the first simple life forms emerged in Earth's primordial oceans."
    },
    {
      heading: "Evolution",
      info: "The gradual process where species change and diversify over long periods, adapting to their environments."
    },
    {
      heading: "First Human",
      info: "Homo sapiens, the anatomically modern humans, appeared around 300,000 years ago."
    },
    {
      heading: "Fire and wheel",
      info: "Early humans harnessed fire for warmth and protection, and later invented the wheel, revolutionising transportation."
    },
    {
      heading: "Agriculture and society",
      info: "Roughly 10,000 years ago, humans transitioned from hunter-gatherers to farmers, laying the groundwork for civilizations."
    },
    {
      heading: "Invention of Zero",
      info: "An essential mathematical concept, zero was first conceptualised in ancient India."
    },
    {
      heading: "Industrialisation",
      info: "The 18th and 19th centuries saw a major shift from agrarian economies to industrial production, drastically changing societies."
    },
    {
      heading: "Scientific Discoveries",
      info: "Advances in knowledge have paved the way for medical, technological, and cosmic breakthroughs."
    },
    {
      heading: "Technology",
      info: "The late 20th and early 21st centuries marked a digital revolution, with the rise of the internet, artificial intelligence, and more."
    },
    {
      heading: "Mars Colonisation,",
      info: "An era of advanced robotics, potential space colonisation, and the ever-present nuclear threat."
    },
    {
      heading: "Space travel",
      info: "Theoretical and potential future milestones in space exploration and extraterrestrial contact."
    },
    {
      heading: "Galactic Civilization",
      info: "A future stage where humanity extends its reach, influencing and settling across the galaxy."
    },
    {
      heading: "Maximum expansion of the universe",
      info: "A point in time when the universe's expansion might slow before potentially reversing."
    },
    {
      heading: "Universal Contraction",
      info: "The hypothesised phase where the universe, after expanding, starts contracting."
    },
    {
      heading: "Big Crunch",
      info: "The theorised endpoint of the universe, where all matter collapses back to a singularity."
    },
    // ... Add more text data here
  ];

  export default textData;